import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { CrudBaseService } from 'src/app/core/base/crud.base.service';
import { Config, IConfig } from 'src/app/core/entities/config';

@Injectable({
  providedIn: 'root',
})
export class ConfigsService extends CrudBaseService<IConfig> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'configs',
      },
      http,
      auth
    );
  }

  public getAccountConfig(account_id: string) {
    return this.listAll({ account_id })
      .toPromise()
      .then((res: any) => res.data.map((data) => new Config().fromJson(data)));
  }

  public doesAccountHaveConfig(account_id: string) {
    return this.getAccountConfig(account_id).then(
      (data: any) => data && data.length > 0
    );
  }
}
