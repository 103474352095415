import { Vehicle } from '../../../core/entities/vehicle';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudBaseService } from '../../../core/base/crud.base.service';
import { AuthService } from '../../auth.service';
import { IVehicle } from 'src/app/core/entities/vehicle';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService extends CrudBaseService<IVehicle> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'vehicles',
      },
      http,
      auth
    );
  }

  public static mapItem(item: IVehicle) {
    return new Vehicle().fromJson(item);
  }
}
