import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { Subscription } from 'rxjs/internal/Subscription';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { DocumentService } from 'src/app/services/CRUD/recogeme/documents.service';
import { LogoService } from 'src/app/services/CRUD/recogeme/logo.service';

@Component({
  selector: 'app-upload-logo',
  templateUrl: './upload-logo.component.html',
})
export class UploadLogoComponent implements LoadableComponent {
  public isLoading: boolean = false;

  public error = '';
  public title: string = '';
  public selectedImageName: string = 'none';
  public hasSelectedImage: boolean = false;
  public noImageName: string = 'profile_default_image';
  public selectedImage;
  public progress = 0;

  public extension;
  public isImage = true;
  private file: File;
  private uploadSub: Subscription;
  private zone: NgZone;

  constructor(
    public dialogRef: MatDialogRef<UploadLogoComponent>,
    private readonly logoService: LogoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: QSnackBar
  ) {
    this.uploadSub = this.logoService.progress.subscribe((data) => {
      this.zone.run(() => {
        this.progress = Math.floor(data);
      });
    });
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  public ngOnInit() {
    this.zone = new NgZone({ enableLongStackTrace: false });
  }

  ngOnDestroy() {
    this.uploadSub.unsubscribe();
  }

  public changedImage($event) {
    if ($event.target.files.length) {
      const file = $event.target.files[0];
      this.file = file;
      this.selectedImageName = file.name;
      this.hasSelectedImage = true;

      const reader = new FileReader();

      this.isImage = /jpg|jpeg|png|svg/.test(this.selectedImageName);
      this.extension = file.name.split('.').pop();

      reader.onload = (e: any) => (this.selectedImage = e.target.result);
      reader.readAsDataURL(file);
    } else {
      this.hasSelectedImage = false;
    }
  }

  public uploadFile() {
    this.setIsLoading(true);
    this.logoService.uploadFile(this.file).subscribe(
      (resp) => {
        this.close(resp);
      },
      (error) => {
        this.snackbar.open('Error uploading: ' + error.message, 'ok');
        this.error = error.message;
        this.setIsLoading(false);
      }
    );
  }

  public close(data?): void {
    this.dialogRef.close(data);
  }
}
