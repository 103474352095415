<mat-card class="no-padding">
    <qbit-table-header [options]="tableOptions" title="VEHICLES" [isLoading]="isLoading">
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters-addable *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        [autocomplete]="autocompleteData" (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams">
    </qbit-table-filters-addable>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'TYPE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <vehicle-type-icon [type]="row.type"></vehicle-type-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="license_plate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'LICENSE_PLATE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list>
                    <mat-chip (click)="$event.stopPropagation()" class="mat-chip mat-primary mat-standard-chip">
                        {{row.license_plate}}</mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <ng-container matColumnDef="brand_make">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'BRAND_MAKE'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.brand}} {{row.make}}</td>
        </ng-container>

        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'COLOR'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.color}} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CREATED'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <i>{{row.created_at | date: 'medium'}}</i>
            </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button *ngIf="user$.isAdmin() || user$.isSuperadmin()" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openEditDialog(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                        <span>{{'EDIT'|translate}}</span>
                    </button>

                    <button mat-menu-item class="col-error" (click)="openRemoveConfirmation(row.id)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">delete</mat-icon>
                        <span>{{'REMOVE'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/vehicles/{{row.id}}">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>