import {
  PermissionAdmin,
  PermissionPublic,
  PermissionSuperAdmin,
  PermissionUser,
} from '@qbitartifacts/caste-client-ng';
import { UserAndBillingOfficerPermission } from './user-billing-officer-permission';
import { UserAndOfficerPermission } from './user-officer-permission';

export const PermissionUserAndOfficer = new UserAndOfficerPermission([
  PermissionAdmin,
  PermissionUser,
  PermissionPublic,
]);

export const PermissionUserAndBillingOfficer =
  new UserAndBillingOfficerPermission([
    PermissionAdmin,
    PermissionUser,
    PermissionPublic,
  ]);

if (PermissionAdmin.subPermissions.indexOf(PermissionUserAndOfficer) == -1) {
  PermissionAdmin.subPermissions.push(PermissionUserAndOfficer);
}
if (
  PermissionAdmin.subPermissions.indexOf(PermissionUserAndBillingOfficer) == -1
) {
  PermissionAdmin.subPermissions.push(PermissionUserAndBillingOfficer);
}

if (
  PermissionSuperAdmin.subPermissions.indexOf(PermissionUserAndOfficer) == -1
) {
  PermissionSuperAdmin.subPermissions.push(PermissionUserAndOfficer);
}

if (
  PermissionSuperAdmin.subPermissions.indexOf(
    PermissionUserAndBillingOfficer
  ) == -1
) {
  PermissionSuperAdmin.subPermissions.push(PermissionUserAndBillingOfficer);
}
