import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(
    private user$: CasteUserService,
    public auth$: AuthService,
    public router: Router
  ) {}

  canActivate(): boolean | UrlTree {
    const userIsPresent = this.user$.hasUser();
    const hasSession = this.auth$.hasSession();
    const sessionActive = this.auth$.sessionActive();

    // If hasSession and userIsPresent, don't allow to view Public Pages
    if (hasSession && sessionActive && userIsPresent) {
      return this.router.parseUrl('dashboard');
    } else {
      return true;
    }
  }
}
