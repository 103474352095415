<qbit-dialog-header title="{{title | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <div class="padd-20">
        <p class="subhead no-margin">{{subtitle | translate}}</p>
        <p class="text-mid">{{description | translate}}</p>

        <div class="flex-row center justify-end marg-top-30 dialog-actions">
            <button mat-button (click)="close()">
                {{'CANCEL' | translate}}
            </button>
            <button mat-raised-button cdkFocusInitial (click)="proceed()" color="warn">
                {{confirmButtonText | translate}}
            </button>
        </div>
    </div>
</div>