import { PermissionUserAndOfficer } from './../../core/permissions/permissions';
import { AuthedGuard } from '../../core/guards/authed.guard';
import { PageBaseComponent } from 'src/app/core/base/page.base';
import { Component, AfterContentInit, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';
import { PermissionUser } from '@qbitartifacts/caste-client-ng';
import { QEventsService } from '@qbitartifacts/qbit-kit-ng';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  extends PageBaseComponent
  implements AfterContentInit, OnInit
{
  static guards: any[] = [AuthedGuard];
  public title = 'DASHBOARD';
  public permission = PermissionUser;
  public showVehiclesPermission = PermissionUserAndOfficer;
  public userType = '';

  constructor(
    title: Title,
    public translate$: TranslateService,
    public app$: AppService,
    public user$: UserService,
    public events$: QEventsService,
    public dashboardService: DashboardService
  ) {
    super(title, translate$);
    events$.on('account:changed').subscribe(() => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.dashboardService.updateStats();
  }
}
