import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { RatesService } from 'src/app/services/CRUD/recogeme/rates.service';
import { CreateRate, IRate } from 'src/app/core/entities/rate';
import { AppService } from 'src/app/services/app.service';
import { isNumberWithPoint } from 'src/app/core/utilities/validators/number-dot-validator';

@Component({
  selector: 'app-edit-rate',
  templateUrl: './edit-rate.component.html',
})
export class EditRateComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public rateData: CreateRate = {
    name: '',
    vehicle_type: 'car',
    day_price: 0,
    hour_price: 0,
    base_price_after_unhooking: 0,
    base_price_before_unhooking: 0,
    min_strip_minutes: 0,
    max_days: 0,
    max_hours_per_day: 0,
    days_hourly_billing_allowed: 0,
  };

  constructor(
    public dialogRef: MatDialogRef<EditRateComponent>,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public service: RatesService,
    public app: AppService,
    @Inject(MAT_DIALOG_DATA) public data: IRate
  ) {
    this.rateData = data;
  }

  // Workaround for angular component issue https://github.com/angular/components/issues/13870
  // Fixed from comment https://github.com/angular/components/issues/13870#issuecomment-502071712
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  /* istanbul ignore next */
  edit() {
    this.setIsLoading(true);

    const data = {
      ...this.form.value,
      vehicle_type: this.rateData.vehicle_type,
      account_id: this.app.getOwner(),
    };

    this.service.update(this.data.id, data).subscribe(
      (resp) => {
        this.snackbar.open('Rate updated');
        this.close(true);
        this.setIsLoading(false);
      },
      (err) => {
        this.setIsLoading(false);
        this.snackbar.open(err.message);
      }
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.data.name, Validators.required],
      day_price: [this.data.day_price, Validators.required],
      hour_price: [this.data.hour_price, Validators.required],
      base_price_after_unhooking: [
        this.data.base_price_after_unhooking,
        Validators.required,
      ],
      base_price_before_unhooking: [
        this.data.base_price_before_unhooking,
        Validators.required,
      ],
      min_strip_minutes: [this.data.min_strip_minutes, Validators.required],
      max_days: [this.data.max_days, Validators.required],
      max_hours_per_day: [this.data.max_hours_per_day, Validators.required],
      days_hourly_billing_allowed: [
        this.data.days_hourly_billing_allowed,
        Validators.required,
      ],
    });
  }

  get day_price() {
    return this.form.get('day_price');
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
