<qbit-dialog-header title="{{'EDIT_VEHICLE' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper" style="max-height: 80vh; overflow-y: auto">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="edit()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{'EDIT_VEHICLE' | translate}}
                </h4>
                <p class="text-mutted marg-bottom-15">{{'EDIT_VEHICLE_DESC' | translate}}</p>

                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'LICENSE_PLATE' | translate}}</mat-label>
                            <input id="license_plate" matInput placeholder="{{'LICENSE_PLATE'| translate}}"
                                formControlName="license_plate">
                            <mat-error
                                *ngIf="license_plate.invalid && (license_plate.dirty) && license_plate.errors.required">
                                {{'LICENSE_PLATE_REQUIRED' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'TYPE' | translate}}</mat-label>
                            <mat-select formControlName="type">
                                <mat-option *ngFor="let vtype of vehicleTypes" [value]="vtype">{{vtype|translate}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="type.invalid && (type.dirty) && type.errors.required">
                                {{'TYPE_REQUIRED' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'BRAND' | translate}}</mat-label>
                            <input id="brand" matInput placeholder="{{'BRAND'| translate}}" formControlName="brand">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'MAKE' | translate}}</mat-label>
                            <input id="make" matInput placeholder="{{'MAKE'| translate}}" formControlName="make">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'COLOR' | translate}}</mat-label>
                            <input id="color" matInput placeholder="{{'COLOR'| translate}}" formControlName="color">
                        </mat-form-field>
                    </div>
                </div>

                <div [@.disabled]="disableAnimation">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="false" class="mat-elevation-z1">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{'STATE' | translate}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{'VEHICLE_STATE_DESCRIPTION' | translate}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div>
                                <mat-form-field>
                                    <mat-label>{{'GENERAL' | translate}}</mat-label>
                                    <mat-select formControlName="general">
                                        <mat-option *ngFor="let t of damageOptions1" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{'CHAPA' | translate}}</mat-label>
                                    <mat-select formControlName="chapa">
                                        <mat-option *ngFor="let t of damageOptions2" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{'LLANTAS' | translate}}</mat-label>
                                    <mat-select formControlName="rims">
                                        <mat-option *ngFor="let t of damageOptionsRims" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{'DOORS' | translate}}</mat-label>
                                    <mat-select formControlName="chapa">
                                        <mat-option *ngFor="let t of damageOptions3" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{'WINDOWS' | translate}}</mat-label>
                                    <mat-select formControlName="windows">
                                        <mat-option *ngFor="let t of damageCristales" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{'LIGHTS' | translate}}</mat-label>
                                    <mat-select formControlName="lights">
                                        <mat-option *ngFor="let t of damageOptions4" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{'INDICATORS' | translate}}</mat-label>
                                    <mat-select formControlName="indicators">
                                        <mat-option *ngFor="let t of damageOptions4" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>{{'REAR_MIRRORS' | translate}}</mat-label>
                                    <mat-select formControlName="rear_mirrors">
                                        <mat-option *ngFor="let t of damageOptions4" [value]="t">{{t|translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="tunning">
                                    {{'TUNNING'|translate}}
                                </mat-checkbox>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [disabled]="form.invalid || isLoading" [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>