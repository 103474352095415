import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';
import { Record } from 'src/app/core/entities/record';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-record',
  templateUrl: './edit-record.component.html',
})
export class EditRecordComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public diplacementTypes = Record.displacementTypes;

  constructor(
    public dialogRef: MatDialogRef<EditRecordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public recordsCrud: RecordsService
  ) {
  }

  /* istanbul ignore next */
  edit() {
    if (!this.form.dirty || this.isLoading) return;

    this.setIsLoading(true);

    const data = {
      started_at: this.form.value.started_at,
      finished_at: this.form.value.finished_at,
      displacement_type: this.form.value.displacement_type,
      observations: this.form.value.observations,
      origin_location: {
        ...this.data.origin_location,
        address: this.form.value.origin_location,
      },
      destiny_location: {
        ...this.data.destiny_location,
        address: this.form.value.destiny_location,
      },
      in_scrapping: this.form.value.in_scrapping,
      inmobilized: this.form.value.inmobilized,
      charged_on_route: this.form.value.charged_on_route,
    };

    this.recordsCrud.update(this.data.id, data).subscribe(
      (resp) => {
        this.snackbar.open('Expediente editado');
        this.close(true);
        this.setIsLoading(false);
      },
      (err) => {
        this.setIsLoading(false);
        this.snackbar.open(err.message);
      }
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      started_at: [
        moment(this.data.started_at).format('yyyy-MM-DDTHH:mm'),
        Validators.required,
      ],
      finished_at: [
        moment(this.data.finished_at).format('yyyy-MM-DDTH:mm'),
        Validators.required,
      ],
      displacement_type: [this.data.displacement_type, Validators.required],
      origin_location: [this.data.origin_location.address],
      destiny_location: [this.data.destiny_location.address],
      observations: [this.data.observations],
      in_scrapping: [this.data.in_scrapping],
      inmobilized: [this.data.inmobilized],
      charged_on_route: [this.data.charged_on_route],
    });
  }

  get displacement_type() {
    return this.form.get('displacement_type');
  }

  get started_at() {
    return this.form.get('started_at');
  }

  get finished_at() {
    return this.form.get('finished_at');
  }

  get origin_location() {
    return this.form.get('origin_location');
  }

  get destiny_location() {
    return this.form.get('destiny_location');
  }

  get observations() {
    return this.form.get('observations');
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
