import { AbstractControl } from '@angular/forms';

var DNI_REGEX = /^(\d{8})([A-Z])$/;
var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;

function validDNI(dni: string) {
    
    var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    var letter = dni_letters.charAt( parseInt( dni, 10 ) % 23 );
  
    return letter == dni.charAt(8);
}

function validNIE(nie: string) {

    // Change the initial letter for the corresponding number and validate as DNI
    var nie_prefix = nie.charAt(0);
  
    switch (nie_prefix) {
        case 'X': 
            nie_prefix = '0';
            break;

        case 'Y': 
            nie_prefix = '1';
            break;

        case 'Z':
            nie_prefix = '2';
            break;

    }
  
    return validDNI(nie_prefix + nie.substr(1)); 
}

function validCIF(cif: string) {

    var match = cif.match( CIF_REGEX );
    var letter  = match[1],
        number  = match[2],
        control = match[3];
  
    var even_sum = 0;
    var odd_sum = 0;
    var n;
  
    for ( var i = 0; i < number.length; i++) {
      n = parseInt( number[i], 10 );
  
      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if ( i % 2 === 0 ) {
        // Odd positions are multiplied first.
        n *= 2;
  
        // If the multiplication is bigger than 10 we need to adjust
        odd_sum += n < 10 ? n : n - 9;
  
      // Even positions
      // Just sum them
      } else {
        even_sum += n;
      }
  
    }
  
    var control_digit = (10 - parseInt((even_sum + odd_sum).toString().substr(-1)));
    var control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );
  
    // Control must be a digit
    if ( letter.match( /[ABEH]/ ) ) {
      return control == control_digit.toString();
  
    // Control must be a letter
    } else if ( letter.match( /[KPQS]/ ) ) {
      return control == control_letter;
  
    // Can be either
    } else {
      return control == control_digit.toString() || control == control_letter;
    }  
}

export function isDniValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {

    if (control.value == null || control.value.length <= 0)
        return { dni: true };

    var valid = false;
    var str = control.value;

    str = str.toUpperCase().replace(/\s/, '');

    if (str.match(DNI_REGEX)) 
        valid = validDNI(str);
    else if (str.match(NIE_REGEX)) 
        valid = validNIE(str);
    else if (str.match(CIF_REGEX))
        valid = validCIF(str);

    if (!valid)
        return { dni: true };

    return null;
}





