<mat-card class="no-padding">
    <qbit-table-header title="IMAGES" [isLoading]="isLoading" [options]="tableOptions">
    </qbit-table-header>

    <qbit-table-filters-addable *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        [autocomplete]="autocompleteData" (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams">
    </qbit-table-filters-addable>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="preview" class="hoverable">
            <th mat-header-cell *matHeaderCellDef> {{'PREVIEW'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <a href="{{env.url}}/{{row.content_url}}" target="_blank">
                    <div class="flex-row center" style="width: 100px; height: 100px; overflow: hidden">
                        <img style="width: 100%;height: auto;" src="{{env.url}}/{{row.content_url}}">
                    </div>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CREATED'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <i>{{row.created_at | date: 'medium'}}</i>
            </td>
        </ng-container>


        <ng-container matColumnDef="record">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'RECORD'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <div class="marg-left-10 row center h v" *ngIf="row.record">
                    <qbit-chip-link link="/records/{{row.record.id}}" icon="receipt" *ngIf="row"
                        [label]="row.record.internal_id">
                    </qbit-chip-link>
                </div>
            </td>
        </ng-container>


        <ng-container matColumnDef="vehicle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'VEHICLE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <div class="marg-left-10 row center h v" *ngIf="row.record">
                    <app-vehicle-chip tooltip="" *ngIf="row && row.record && row.record.vehicle"
                        [vehicle]="row.record.vehicle">
                    </app-vehicle-chip>
                </div>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>