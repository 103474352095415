<mat-card class="no-padding">
    <qbit-table-header title="QUERIES" [isLoading]="isLoading" [options]="{'showBreadcrumbs': showBreadcrumbs}">
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters-addable *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        [autocomplete]="autocompleteData" (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams">
    </qbit-table-filters-addable>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">
        <ng-container matColumnDef="ip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'IP'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <code>{{row.ip}}</code></td>
        </ng-container>

        <ng-container matColumnDef="license_plate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'VEHICLE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <app-vehicle-chip *ngIf="row && row.record && row.record.vehicle" [vehicle]="row.record.vehicle">
                </app-vehicle-chip>

                <qbit-chip tooltip="Esta consulta no a encontrado ningun vehiculo" *ngIf="row && !row.record"
                    [label]="row.plate"></qbit-chip>
            </td>
        </ng-container>

        <ng-container matColumnDef="record">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'RECORD'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <qbit-chip-link *ngIf="row.record"
                    [label]="row.record.vehicle ? row.record.vehicle.license_plate: row.record.id"
                    link="/records/{{row.record.id}}">
                </qbit-chip-link>
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CREATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <i>{{row.created_at | date: 'medium'}}</i></td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>