import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppService } from './app.service';
import { QueriesService } from './CRUD/recogeme/queries.service';
import { RecordsService } from './CRUD/recogeme/records.service';
import { VehiclesService } from './CRUD/recogeme/vehicles.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public recordsCount = new BehaviorSubject(0);
  public vehiclesCount = new BehaviorSubject(0);
  public queriesCount = new BehaviorSubject(0);

  constructor(
    private records$: RecordsService,
    private app$: AppService,
    private vehicles$: VehiclesService,
    private queries$: QueriesService
  ) {}

  updateStats() {
    const account_id = this.getOwner();
    const params = { account_id };
    const paramsQueries = {
      'record.account_id': account_id,
    };

    this.records$
      .getTotalItems(params)
      .subscribe((v) => this.recordsCount.next(v));

    this.vehicles$
      .getTotalItems(params)
      .subscribe((v) => this.vehiclesCount.next(v));

    this.queries$
      .getTotalItems(paramsQueries)
      .subscribe((v) => this.queriesCount.next(v));
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }
}
