import { Component, Input, OnInit, Query } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { QEventsService, QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { environment } from 'src/environments/environment';
import { BillsService } from 'src/app/services/CRUD/recogeme/bills.service';
import { IBill } from 'src/app/core/entities/bill';
import { RecogemeTablePageBase } from 'src/app/core/base/recogeme.table.base';

@Component({
  selector: 'app-bills-list',
  templateUrl: './bills-list.component.html',
})
export class BillsListComponent
  extends RecogemeTablePageBase<IBill>
  implements OnInit
{
  displayedColumns: string[] = [
    'number',
    'name',
    'last_name',
    'vehicle',
    'record',
    'rate',
    'status',
    'amount',
    'created_at',
    'options',
  ];
  query: string;
  isLoading = false;
  filterByOwner = true;
  env = environment;

  @Input() itemQueries: Query[];
  @Input() showBreadcrumbs = true;

  public autocompleteData = {};

  constructor(
    public snackbar: QSnackBar,
    public dialogs: DialogsService,
    public events: QEventsService,
    public app: AppService,
    public bills$: BillsService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(snackbar, events, router, activatedRoute);
    this.initialSearch = true;
    this.autoRefresh = true;
  }

  public getSearchObservable(queryParams: {
    [key: string]: string;
  }): Observable<any> {
    if (this.itemQueries) {
      return of({
        data: this.itemQueries,
        total: this.itemQueries.length,
        search: [],
      });
    } else {
      return this.bills$.listAll(queryParams);
    }
  }

  public getRemoveItemObservable(id: string): Observable<any> {
    return this.bills$.remove(id);
  }

  public getRemoveItemDialog(id: string) {
    return this.dialogs.openConfirmDelete();
  }

  public addNewBill() {
    this.dialogs
      .openCreateBill()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.searchParams);
      });
  }

  public editBill(bill: IBill) {
    this.dialogs
      .openEditBill({ bill: bill, record: bill.record })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.searchParams);
      });
  }

  viewBillPdf(item: any) {
    const pdfUrl = environment.url + '/files/' + item.pdf;
    window.open(pdfUrl, '_blank');
  }

  public getOwner(): string {
    return this.app.getOwner();
  }
}
