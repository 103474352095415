import { Component } from '@angular/core';
import { QApiSelectorComponent } from '@qbitartifacts/qbit-kit-ng';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-status-selector',
  templateUrl: './status-selector.component.html',
})
export class StatusSelector extends QApiSelectorComponent {
  label: string = 'SELECT_STATUS';

  constructor(public translate: TranslateService) {
    super();
  }

  getSearchObservable(query: string): Observable<any> {
    return of(
      this.items.filter((t) => {
        const matches = t.toLowerCase().startsWith(query);
        const matchesTranslated = this.translate
          .instant(t)
          .toLowerCase()
          .startsWith(query);

        return matches || matchesTranslated;
      })
    );
  }
}
