import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { CrudBaseService } from 'src/app/core/base/crud.base.service';
import { IDocument, Document } from 'src/app/core/entities/document';
import { FileUploaderService } from '../../file-uploader.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class LogoService extends CrudBaseService<IDocument> {
  constructor(
    http: HttpClient,
    auth: AuthService,
    private readonly fileUploader: FileUploaderService
  ) {
    super(
      {
        endpoint: 'logos',
      },
      http,
      auth
    );
  }

  get progress() {
    return this.fileUploader.uploadprogress$;
  }

  uploadFile(file: string | Blob) {
    const path = `${environment.url}/${this.opts.endpoint}`;
    return this.fileUploader
      .uploadFile(path, file)
      .pipe(map((result) => this.mapItem(result)));
  }

  public mapItem(item: IDocument) {
    return new Document().fromJson(item);
  }
}
