import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigsService } from 'src/app/services/CRUD/recogeme/configs.service';
import { Document } from 'src/app/core/entities/document';

export interface CreateConfigData {
  account_id: string;
}

@Component({
  selector: 'app-create-config',
  templateUrl: './create-config.component.html',
})
export class CreateConfigComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public account_id: string;
  public logo: Document;

  // Workaround for angular component issue https://github.com/angular/components/issues/13870
  // Fixed from comment https://github.com/angular/components/issues/13870#issuecomment-502071712
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  constructor(
    public dialogRef: MatDialogRef<CreateConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateConfigData,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public config: ConfigsService,
    public app: AppService,
    public dialogs: DialogsService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.account_id = this.data.account_id;
    this.form = this.formBuilder.group({
      bill_header: [],
      bill_footer: [],
      company_name: [],
    });
  }

  /* istanbul ignore next */
  add() {
    this.setIsLoading(true);

    this.config
      .create({
        ...this.form.value,
        account_id: this.account_id,
        logo: this.logo ? (('/logos/' + this.logo['id']) as any) : null,
      })
      .subscribe(
        (resp) => {
          this.snackbar.open('Configuracion creada');
          this.close(true);
          this.setIsLoading(false);
        },
        (err) => {
          this.setIsLoading(false);
          this.snackbar.open(err.message);
        }
      );
  }

  logoPicked($event: Document) {
    this.logo = $event;
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
