<qbit-dialog-header title="{{'EDIT_BILL' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="edit()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-muted">{{'RECORD'| translate}} <b>{{record.vehicle.license_plate}} ({{record.internal_id}})</b></p>
            </div>

            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'NAME'|translate}}</mat-label>
                            <input matInput type="text" formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'LASTNAME'|translate}}</mat-label>
                            <input matInput type="text" formControlName="last_name">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'DNI'|translate}}</mat-label>
                            <input matInput type="text" formControlName="dni">
                            <mat-error *ngIf="dni.invalid && (dni.dirty) && dni.errors.dni">
                                {{'INVALID_DNI' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'EMAIL'|translate}}</mat-label>
                            <input matInput type="text" formControlName="email">
                            <mat-error *ngIf="email.invalid && (email.dirty) && email.errors.email">
                                {{'INVALID_EMAIL' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'CITY'|translate}}</mat-label>
                            <input matInput type="text" formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'ADDRESS'|translate}}</mat-label>
                            <input matInput type="text" formControlName="address">
                        </mat-form-field>
                    </div>
                </div>


                <div class="row">
                    <!-- <div class="col-lg-6 col-md-12 col-sm-12">
                        <app-record-selector [filters]="recordSelectorFilters" label="RECORD"
                            [(item)]="record"></app-record-selector>
                    </div> -->
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <app-rate-selector [filters]="rateSelectorFilters" label="RATE" [(item)]="rate">
                        </app-rate-selector>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <app-bill-status-selector [(item)]="status"></app-bill-status-selector>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'DATED_DATE'|translate}}</mat-label>
                            <input matInput type="datetime-local" formControlName="dated_at">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="column">
                            <label id="payment-type-radio-label"
                                class="marg-bottom-10 custom-label">{{'PAYMENT_TYPE'|translate}}</label>
                            <mat-radio-group formControlName="payment_type" class="" aria-labelledby="payment-type-radio-label" (change)="paymentTypeTotals()">
                                <mat-radio-button color="primary" [value]="null">
                                    {{'none' | translate}}
                                </mat-radio-button>
                                <mat-radio-button color="primary" *ngFor="let type of paymentTypes" [value]="type">
                                    {{type | translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>

                <div class="row marg-top-15"
                    *ngIf="record && !record.inmobilized_documentation_presented && record.displacement_type == 'EN' && record.inmobilized">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <p class="marg-top-15" style="margin-top:0!important">
                            {{'DOCUMENTS_EN' | translate}}
                        </p>
                        <p class="text-mutted marg-top-15" style="margin-top:0!important">
                            {{'DOCUMENTS_EN_DESC' | translate}}
                        </p>

                        <button mat-raised-button color="primary" (click)="addDocument()">
                            {{'ADD_DOCUMENT'| translate}}
                        </button>

                    </div>
                </div>
                <div class="row marg-top-15" id="divPaymentTotals">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'cash'|translate}}</mat-label>
                            <input matInput type="number" formControlName="total_cash">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <input matInput type="number" formControlName="total_card">
                        </mat-form-field>
                    </div>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [disabled]="form.invalid || isLoading || !hasChanged" [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>