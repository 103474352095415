import { RatesService } from '../../../../services/CRUD/recogeme/rates.service';
import { Component, Input } from '@angular/core';
import { QApiSelectorComponent } from '@qbitartifacts/qbit-kit-ng';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-rate-selector',
  templateUrl: './rate-selector.component.html',
})
export class RateSelectorComponent extends QApiSelectorComponent {
  label: string = 'SELECT_RATE';
  @Input() public userType: any = '';

  constructor(public rates$: RatesService) {
    super();
  }

  getSearchObservable(query: string): Observable<any> {
    return this.rates$.listAll(
      { ...this.filters, 'vehicle.license_plate': query },
      this.userType
    );
  }
}
