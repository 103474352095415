import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
})
export class ImagePickerComponent {
  @Input() src;
  @Input() label = 'IMAGE';
  @Input() noImageSrc = '../assets/no-image.png';
  @Output() onChangeImage: EventEmitter<any> = new EventEmitter();

  add() {
    this.onChangeImage.emit();
  }
}
