import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { Subscription } from 'rxjs/internal/Subscription';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { IBill } from 'src/app/core/entities/bill';
import { IRecord } from 'src/app/core/entities/record';
import { DocumentService } from 'src/app/services/CRUD/recogeme/documents.service';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
})
export class AddDocumentComponent implements LoadableComponent {
  public isLoading: boolean = false;

  public error = '';
  public title: string = '';
  public selectedImageName: string = 'none';
  public hasSelectedImage: boolean = false;
  public noImageName: string = 'profile_default_image';
  public selectedImage;
  public progress = 0;

  public extension;
  public isImage = true;
  private file: File;
  private uploadSub: Subscription;
  private zone: NgZone;

  constructor(
    public dialogRef: MatDialogRef<AddDocumentComponent>,
    private readonly documentsService: DocumentService,
    private readonly recordService: RecordsService,
    @Inject(MAT_DIALOG_DATA) public data: { record: IRecord },
    public snackbar: QSnackBar
  ) {
    this.uploadSub = this.documentsService.progress.subscribe((data) => {
      this.zone.run(() => {
        this.progress = Math.floor(data);
      });
    });
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  public ngOnInit() {
    if (!this.data.record) {
      throw new Error('Record is required when adding a new document');
    }

    this.zone = new NgZone({ enableLongStackTrace: false });
  }

  ngOnDestroy() {
    this.uploadSub.unsubscribe();
  }

  public changedImage($event) {
    if ($event.target.files.length) {
      const file = $event.target.files[0];
      this.file = file;
      this.selectedImageName = file.name;
      this.hasSelectedImage = true;

      const reader = new FileReader();

      this.isImage = /jpg|jpeg|png|svg/.test(this.selectedImageName);
      this.extension = file.name.split('.').pop();

      reader.onload = (e: any) => (this.selectedImage = e.target.result);
      reader.readAsDataURL(file);
    } else {
      this.hasSelectedImage = false;
    }
  }

  public uploadFile() {
    this.setIsLoading(true);
    this.documentsService.uploadFile(this.file).subscribe(
      (resp) => {
        this.addDocumentToRecord(resp);
      },
      (error) => {
        this.snackbar.open('Error uploading: ' + error.message, 'ok');
        this.error = error.message;
        this.setIsLoading(false);
      }
    );
  }

  public addDocumentToRecord(document: any) {
    this.recordService
      .update(this.data.record.id, {
        documents: [('/documents/' + document['id']) as any],
        inmobilized_documentation_presented: true,
      })
      .subscribe(
        (resp) => {
          this.setIsLoading(false);
          this.close(true);
        },
        (error) => {
          this.setIsLoading(false);
          this.snackbar.open(
            'Error assigning document: ' + error.message,
            'ok'
          );
          this.error = error.message;
        }
      );
  }

  public close(confirmed = false): void {
    this.dialogRef.close(confirmed);
  }
}
