import { PageBaseComponent } from 'src/app/core/base/page.base';
import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import {
  InternalPermission,
  PERMISSIONS,
} from '@qbitartifacts/caste-client-ng';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
})
export class ClientsComponent
  extends PageBaseComponent
  implements AfterContentInit {
  static guards: any[] = [AuthedGuard, AdminGuard];
  static permissions: InternalPermission[] = [PERMISSIONS.sadmin];

  public title = 'CLIENTS';

  constructor(title: Title, translate$: TranslateService) {
    super(title, translate$);
  }
}
