import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'ngx-avatar';
import { QbitKitModule } from './qbitkit.module';

@NgModule({
  imports: [
    MaterialModule,
    QbitKitModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
  ],
  exports: [
    MaterialModule,
    BrowserModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    QbitKitModule,
  ],
})
export class SharedModule {}
