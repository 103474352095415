import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CasteAuthService,
  PermissionUser,
} from '@qbitartifacts/caste-client-ng';
import { QEventsService, QLocalesService } from '@qbitartifacts/qbit-kit-ng';
import { IDLE_CHECK_ENABLED } from 'src/app/config/consts';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { SessionCheckerService } from 'src/app/services/session-checker.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public title = environment.brand.title;
  public permissionUser = PermissionUser;
  public IDLE_CHECK_ENABLED = IDLE_CHECK_ENABLED;
  public activeRoute;

  constructor(
    public user$: UserService,
    public auth$: AuthService,
    public casteAuth$: CasteAuthService,
    public app$: AppService,
    public dialogs$: DialogsService,
    public sessionChecker: SessionCheckerService,
    public events: QEventsService,
    public router: Router,
    public dialog: MatDialog,
    public location: Location,
    public qlocales: QLocalesService
  ) {
    this.setupEvents();
  }

  /* istanbul ignore next */
  ngOnDestroy() {}

  ngOnInit() {
    if (this.auth$.isSessionSaved()) {
      this.recoverSessionAndUser();
    }

    this.qlocales.setup();
  }

  private setupEvents() {
    this.events.on(AuthService.LOGIN_EVENT).subscribe(async (resp) => {
      if (this.IDLE_CHECK_ENABLED) {
        // this.sessionChecker.initCheckIdle();
      }
    });

    this.events.on(AuthService.LOGOUT_EVENT).subscribe((resp) => {
      this.auth$.removeSession();
      this.user$.setUser(null);
      this.router.navigate(['/login']);
      localStorage.removeItem(SessionCheckerService.LS_IDLE_KEY);
    });

    this.events.on('account:changed').subscribe((resp) => {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    });
  }

  /* istanbul ignore next */
  private recoverSessionAndUser() {
    const savedSession = this.auth$.recoverSession();
    const sessionActive = savedSession && savedSession.isActive();

    if (!sessionActive) return this.events.fire(AuthService.LOGOUT_EVENT);

    const tokenData: any = savedSession.getDecodedToken();

    this.casteAuth$.saveToken(savedSession.token);
    this.user$.setUserFromTokenData(tokenData);
    this.user$.setApplication(tokenData.application);
    this.auth$.setSession(savedSession);

    this.events.fire(AuthService.LOGIN_EVENT);
  }
}
