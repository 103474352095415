export const durations = {
  second: 1e3,
  minute: 60e3,
};

// Max amount of time a user can be inactive
export const IDLE_MAX_TIME = 30e3; // (milliseconds)

// Time in between idle checks
export const IDLE_REFRESH = durations.second; // (milliseconds)

export const REFRESH_INTERVAL_MS = durations.minute; // (milliseconds)

export const IDLE_CHECK_ENABLED = false;

// Service cache max age
export const RECOGEME_REQ_CACHE_MAX_AGE = durations.minute * 10;
