import { VehicleTypeSelector } from './selectors/vehicle-type-selector/vehicle-type-selector.component';
import { NgModule } from '@angular/core';
import { AggregateComponent } from './aggregate/aggregate.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserAvatarMenuComponent } from './user-avatar-menu/user-avatar-menu.component';
import { UserDetailsComponent } from './details/user-details/user-details.component';
import { SharedModule } from '../../shared.module';
import { RecordsListComponent } from './lists/records-list/records-list.component';
import { VehiclesListComponent } from './lists/vehicles-list/vehicles-list.component';
import { MediaListComponent } from './lists/media-list/media-list.component';
import { QueriesListComponent } from './lists/queries-list/queries-list.component';
import { VehicleChipComponent } from './vehicle-chip/vehicle-chip.component';
import { VehicleTypeIconComponent } from './vehicle-type-icon/vehicle-type-icon.component';
import { CasteLinkComponent } from './caste-link/caste-link.component';
import { BillsListComponent } from './lists/bills-list/bills-list.component';
import { RecordSelectorComponent } from './selectors/record-selector/record-selector.component';
import { RateSelectorComponent } from './selectors/rate-selector/rate-selector.component';
import { RatesListComponent } from './lists/rates-list/rates-list.component';
import { StatusSelector } from './selectors/status-selector/status-selector.component';
import { BillStatusSelector } from './selectors/status-selector/bill-status-selector.component';
import { RecordChipComponent } from './record-chip/record-chip.component';
import { RateChipComponent } from './rate-chip/rate-chip.component';
import { CustomSliderComponent } from './custom-slider/custom-slider.component';
import { KeyValuePair } from './kvp/kvp-list/kvp-list';
import { KeyValueItem } from './kvp/kvp-item/kvp-item';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { LogoPickerComponent } from './logo-picker/logo-picker.component';

const components = [
  AggregateComponent,
  ToolbarComponent,
  UserAvatarMenuComponent,
  VehicleChipComponent,
  RecordChipComponent,
  VehicleTypeIconComponent,
  RateChipComponent,
  KeyValuePair,
  KeyValueItem,
  ImagePickerComponent,
  LogoPickerComponent,

  CustomSliderComponent,

  // Caste
  CasteLinkComponent,

  // Details Components
  UserDetailsComponent,

  // Lists
  RecordsListComponent,
  VehiclesListComponent,
  QueriesListComponent,
  MediaListComponent,
  BillsListComponent,
  RatesListComponent,

  // Selectors
  RecordSelectorComponent,
  RateSelectorComponent,
  VehicleTypeSelector,
  StatusSelector,
  BillStatusSelector,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [SharedModule],
})
export class ComponentsModule {}
