<div class="bootstrap-wrapper padd-v-20 padd-h-20 ">
    <div class="row">
        <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12" style="padding: 0">
            <mat-card class="marg-bottom-15">
                <div class="centered" style="width: 100%;">
                    <img src="https://avatars1.githubusercontent.com/u/17043260?s=60&v=4" aria-label="User avatar"
                        class="avatar-lg">

                    <h4 class="title-lg">Manolo Edge</h4>
                    <p class="text-mutted no-margin">manoloedge96@gmail.com</p>
                </div>
            </mat-card>

            <mat-card class="marg-bottom-15">
                <div class="row">
                    <div class="col-5 offset-1">
                        <div class="marg-bottom-10">
                            <b class="text-subtitle">Name</b>
                            <div class="flex-row center padd-v-10">
                                <p class="no-margin text-mid">
                                    Manolo
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-5 offset-1">
                        <div class="marg-bottom-10">
                            <b class="text-subtitle">Lastname</b>
                            <div class="flex-row center padd-v-10">
                                <p class="no-margin text-mid">
                                    Edge Tejero
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-5 offset-1">
                        <div class="marg-bottom-10">
                            <b class="text-subtitle">Email</b>
                            <div class="flex-row center padd-v-10">
                                <p class="no-margin text-mid">
                                    manoloedge96@gmail.com
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-5 offset-1">
                        <div class="marg-bottom-10">
                            <b class="text-subtitle">Birth Date</b>
                            <div class="flex-row center padd-v-10">
                                <p class="no-margin text-mid">
                                    14/12/1996
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>

            <mat-card>
                <div class="row">
                    <div class="col-5 offset-1">
                        <div class="marg-bottom-10">
                            <b class="text-subtitle">Password</b>
                            <div class="flex-row center padd-v-10">
                                <p class="no-margin text-mid">
                                    **********
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-5 offset-1">
                        <div class="marg-bottom-10">
                            <b class="text-subtitle">
                                2FA <mat-icon class="col-green mat-icon-small">check</mat-icon>
                            </b>
                            <div class="flex-row center padd-v-10">
                                <p class="no-margin text-mid">
                                    Active
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>