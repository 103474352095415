import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PageBaseComponent } from './page.base';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Component({ template: '' })
export abstract class ItemPageBaseComponent<T = any>
  extends PageBaseComponent
  implements OnInit {
  public itemId = null;
  public itemData: T;

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public route: ActivatedRoute
  ) {
    super(title$, translate$);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.itemId = params.id;
      this.loadItem();
    });
  }

  abstract getItemObservable(id: string): Observable<T>;

  public mapItem(item: T) {
    return item;
  }

  loadItem() {
    let obs = this.getItemObservable(this.itemId);
    obs = obs.pipe(map((el) => this.mapItem(el)));

    obs.subscribe((resp) => {
      this.itemData = resp;
      this.onGotItem(this.itemData);
    });
  }

  public onGotItem(item: T) {}
}
