import { IBill } from '../../../core/entities/bill';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { IQuery } from 'src/app/core/entities/query';
import { CrudBaseService } from 'src/app/core/base/crud.base.service';

@Injectable({
  providedIn: 'root',
})
export class BillsService extends CrudBaseService<IBill> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'bills',
      },
      http,
      auth
    );
  }
}
