import { PageBaseComponent } from 'src/app/core/base/page.base';
import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';
import { ActivatedRoute } from '@angular/router';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss'],
})
export class RecordsComponent extends PageBaseComponent
    implements AfterContentInit {
    static guards: any[] = [AuthedGuard];
    public title = 'RECORDS';

    constructor(
        title: Title,
        translate$: TranslateService,
        public route: ActivatedRoute,
        public records$: RecordsService
    ) {
        super(title, translate$);
    }
}
