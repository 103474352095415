<button id="user-menu" mat-icon-button [matMenuTriggerFor]="menu" aria-label="User menu">
    <ngx-avatar size="40" [name]="user$.user ? user$.user.username: ''"></ngx-avatar>
</button>
<mat-menu #menu="matMenu">
    <div class="padd-h-20 padd-v-15 column center" *ngIf="user$.user">
        <h3 class="no-margin title">{{user$.user ? user$.user.username: ''}}</h3>
        <code *appPermissions="adminPermission" class="admin-badge">ADMIN</code>
    </div>
    <hr>
    <mat-checkbox class="marg-left-15 title" (change)="changeDarkTheme($event)" [checked]="app$.darkThemeEnabled">
        {{'DARK_THEME' | translate}}
    </mat-checkbox>
    <hr>
    <button *ngIf="config == null" mat-menu-item aria-label="Creates a config" (click)="createConfig()">
        <mat-icon class="icon-small">settings</mat-icon>
        <span>{{'CREATE_CONFIG'|translate}}</span>
    </button>

    <button *ngIf="config != null" mat-menu-item aria-label="Updated a config" (click)="editConfig()">
        <mat-icon class="icon-small">settings</mat-icon>
        <span>{{'UPDATE_CONFIG'|translate}}</span>
    </button>

    <button id="logout" mat-menu-item aria-label="Exits the app" (click)="logout()">
        <mat-icon class="icon-small">exit_to_app</mat-icon>
        <span>{{'LOGOUT'|translate}}</span>
    </button>
</mat-menu>