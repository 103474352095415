import { IRecord } from './record';
import { IRate } from './rate';
import { IAccount } from '@qbitartifacts/caste-client-ng';

export type PaymentType = 'card' | 'cash' | 'mixed';

export const PaymentTypes = {
  card: 'card',
  cash: 'cash',
  mixed: 'mixed',
};
export const BillStatuses = <const>['draft', 'pending', 'paid'];
export type BillStatus = typeof BillStatuses[number];

export interface IBill {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  last_name: string;
  email: string;
  dni: string;
  city: string;
  address: string;
  record: IRecord;
  rate: IRate;
  amount: number;
  status: BillStatus;
  days: number;
  hours: number;
  payment_type: PaymentType;
  day_price: number;
  hour_price: number;
  base_price: number;
  account: IAccount;
  total_cash: number;
  total_card: number;
  dated_at: string;
}

export class Bill implements IBill {
  static STATUS_DRAFT: BillStatus = 'draft';
  static STATUS_PENDING: BillStatus = 'pending';
  static STATUS_PAID: BillStatus = 'paid';
  static STATUSES: BillStatus[] = [
    Bill.STATUS_DRAFT,
    Bill.STATUS_PENDING,
    Bill.STATUS_PAID,
  ];
  static SELECTABLE_STATUSES: BillStatus[] = [
    Bill.STATUS_DRAFT,
    Bill.STATUS_PAID,
  ];

  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  last_name: string;
  email: string;
  dni: string;
  city: string;
  address: string;
  record: IRecord;
  rate: IRate;
  amount: number;
  status: BillStatus;
  days: number;
  hours: number;
  payment_type: PaymentType;
  day_price: number;
  hour_price: number;
  base_price: number;
  account: IAccount;
  total_cash: number;
  total_card: number;
  dated_at: string;
}
