import * as pkg from '../../package.json';

export interface Env {
  url: string;
  production: boolean;
  debug: boolean;
  brand: {
    title: string;
  };
  caste: {
    realm: string;
    url: string;
    panelUrl: string;
  };
  version: string;
}

export const environment: Env = {
  ...window['__env'],
  version: pkg.version,
};
