<qbit-dialog-header title="{{'ADD_LOGO' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="flex-row" style="width: 100%; align-items: flex-start; padding: 15px">
    <div class="flex-row centered"
        style="height: 150px; width: 150px; min-width: 150px;overflow:hidden; background: #00000038">
        <img *ngIf="isImage" src="{{selectedImage || '../assets/no-image.png'}}" style="width: auto; height: 150px">
        <div *ngIf="!isImage" class="center h v">
            <h4 class="col-white">{{extension}}</h4>
        </div>
    </div>

    <div class="column centered" style="margin-left: 15px;min-height: 150px;width: 100%">
        <div class="column centered">
            <label class="cta-btn bg-primary hoverable" for="image">
                <span class="small-text">{{'SELECT_FILE' | translate}}</span>
            </label>
            <input type="file" name="" value="" [disabled]="isLoading" id="image" style="display: none"
                (change)="changedImage($event)">
            <span *ngIf="hasSelectedImage" class="text-small" style="margin-top: 10px;">
                {{selectedImageName | translate}}
            </span>
        </div>


        <div style="width: 100%;margin-top:20px" *ngIf="hasSelectedImage">
            <qbit-loadable-button (click)="uploadFile()"
                [disabled]="!selectedImageName && !hasSelectedImage || isLoading" [loading]="isLoading">
                {{(isLoading ? 'ADDING': 'ADD') |translate}}
            </qbit-loadable-button>

            <p class="text-danger small-text" *ngIf="error">{{error|translate}}</p>
            <div class="group-row-left center v" style="margin-top: 15px; width: 100%" *ngIf="progress > 0">
                <mat-progress-bar mode="determinate" color="primary" [value]="progress"></mat-progress-bar>
                <span class="text-mutted">
                    <b>{{ progress }}</b>%
                </span>
            </div>
        </div>
    </div>
</div>