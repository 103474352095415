import { Component, Input } from '@angular/core';
import { VehicleTypeIconComponent } from '../vehicle-type-icon/vehicle-type-icon.component';
import { Record } from 'src/app/core/entities/record';

@Component({
  selector: 'app-record-chip',
  templateUrl: './record-chip.component.html',
})
export class RecordChipComponent {
  @Input() record: Record;
  @Input() tooltip = 'RECORD';

  icon: string;

  ngOnInit() {
    this.icon = VehicleTypeIconComponent.getVehicleIcon(this.record.vehicle.type);
  }
}
