import { IRate } from '../../core/entities/rate';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { IRecord, Record } from 'src/app/core/entities/record';
import { BillsService } from 'src/app/services/CRUD/recogeme/bills.service';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { TranslateService } from '@ngx-translate/core';

export interface CreateBillDialogData {
  record?: IRecord;
  disableRecordSelector?: boolean;
}

@Component({
  selector: 'app-create-bill',
  templateUrl: './create-bill.component.html',
})
export class CreateBillComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public record: IRecord;
  public rate: IRate;
  public disableRecordSelector = false;
  public recordSelectorFilters = {};
  public rateSelectorFilters = {};

  constructor(
    public dialogRef: MatDialogRef<CreateBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateBillDialogData,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public billService: BillsService,
    public app: AppService,
    public dialogs: DialogsService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    console.log('DATA', this.data);

    if (this.data && this.data.disableRecordSelector != null) {
      this.disableRecordSelector = this.data.disableRecordSelector;
    }

    if (this.data && this.data.record) {
      this.record = this.data.record;
    }

    this.recordSelectorFilters = {
      account_id: this.app.getOwner(),
      // Checks that records have no bills
      'exists[bill]': false,
      displacement_type: 'EN',
    };
    this.rateSelectorFilters = { account_id: this.app.getOwner() };

    this.form = this.formBuilder.group({});
  }

  /* istanbul ignore next */
  edit() {
    if (!this.record || !this.rate || this.isLoading) return;

    this.setIsLoading(true);

    const data = {
      ...this.form.value,
      record: this.record['@id'],
      rate: this.rate['@id'],
      account_id: this.app.getOwner(),
    };

    this.billService.create(data).subscribe(
      (resp) => {
        this.snackbar.open('Factura creada');
        this.close(true);
        this.setIsLoading(false);
      },
      (err) => {
        this.setIsLoading(false);
        this.snackbar.open(this.translate.instant(err.message));
      }
    );
  }

  addDocument() {
    this.dialogs
      .openAddDocument(this.record)
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          this.snackbar.open('DOCUMENT_ADDED_CORRECTLY');
        }
      });
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
