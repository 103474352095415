import { AutocompleteItem, QTableBase } from '@qbitartifacts/qbit-kit-ng';

export abstract class RecogemeTablePageBase<T> extends QTableBase<T> {
  public processSearchMapping(resp) {
    console.log('resp', resp);
    return super
      .processSearchMapping(resp)
      .filter((el) => !el.variable.includes('strict'))
      .map((mapping) => {
        let input_type = 'text';

        if (String(mapping.variable).includes('_at')) {
          input_type = 'datetime-local';
        } else if (
          String(mapping.variable).includes('has') ||
          String(mapping.variable).includes('exists')
        ) {
          input_type = 'checkbox';
        }

        mapping.input_type = input_type;
        // console.log('mapping', mapping, input_type);
        return mapping;
      });
  }

  public static createAutocompleteItem(value): AutocompleteItem {
    return {
      display: value,
      value: value,
    };
  }
}
