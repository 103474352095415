import { VehicleTypes } from './../../../entities/vehicle';
import { Component, OnInit } from '@angular/core';
import { IVehicle } from 'src/app/core/entities/vehicle';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { VehiclesService } from 'src/app/services/CRUD/recogeme/vehicles.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  QEventsService,
  QSnackBar,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { EditVehicleComponent } from 'src/app/dialogs/edit-vehicle/edit-vehicle.component';
import { RecogemeTablePageBase } from 'src/app/core/base/recogeme.table.base';
import { RecordStatuses } from 'src/app/core/entities/record';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-vehicles-list',
  templateUrl: './vehicles-list.component.html',
})
export class VehiclesListComponent
  extends RecogemeTablePageBase<IVehicle>
  implements OnInit
{
  public displayedColumns: string[] = [
    'type',
    'license_plate',
    'brand_make',
    'color',
    'created_at',
    'options',
  ];
  public query: string;
  public isLoading = false;
  public filterByOwner = true;
  public env = environment;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: true,
  };
  public autocompleteData = {
    'record.status': RecordStatuses.map(
      RecogemeTablePageBase.createAutocompleteItem
    ),
    type: VehicleTypes.map(RecogemeTablePageBase.createAutocompleteItem),
  };

  constructor(
    public snackbar: QSnackBar,
    public dialogs: DialogsService,
    public events: QEventsService,
    public app: AppService,
    public user$: UserService,
    public vehicles$: VehiclesService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(snackbar, events, router, activatedRoute);
    this.initialSearch = true;
    this.autoRefresh = true;
  }

  public getSearchObservable(queryParams: {
    [key: string]: string;
  }): Observable<any> {
    return this.vehicles$.listAll(queryParams);
  }

  public getRemoveItemObservable(id: string): Observable<any> {
    return this.vehicles$.remove(id);
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs.openConfirmDelete();
  }

  public openEditDialog(item) {
    this.dialogs
      .open(EditVehicleComponent, item)
      .afterClosed()
      .subscribe((e) => {
        if (e) this.onSearch(this.searchParams);
      });
  }

  public getOwner(): string {
    return this.app.getOwner();
  }
}
