import { IAccount } from '@qbitartifacts/caste-client-ng';
import { SerializableEntity } from '../base/serializable-entity.base';
import { IDocument, Document } from './document';
import { IRecogemeLocation } from './location';

export interface IConfig {
  id: string;
  bill_header: string;
  bill_footer: string;
  company_name: string;
  updated_at: string;
  account_id: string;
  account: IAccount;
  logo: Document;
  locations: IRecogemeLocation[];
}

export class Config implements SerializableEntity, IConfig {
  id: string;
  bill_header: string;
  bill_footer: string;
  company_name: string;
  updated_at: string;
  account_id: string;
  account: IAccount;
  logo: Document;
  locations: IRecogemeLocation[];

  fromJson(obj: {}): Config {
    const config = new Config();
    // tslint:disable-next-line: forin
    for (const key in obj) {
      if (key === 'logo') {
        config[key] = new Document().fromJson(obj[key]);
        continue;
      }

      config[key] = obj[key];
    }
    return config;
  }

  toJson?(obj: {}): void {
    throw new Error('Method not implemented.');
  }
}
