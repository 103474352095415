import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QEventsService, QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigsService } from 'src/app/services/CRUD/recogeme/configs.service';
import { IConfig } from 'src/app/core/entities/config';
import { IRecogemeLocation } from 'src/app/core/entities/location';
import { Document } from 'src/app/core/entities/document';

export interface EditConfigData {
  account_id: string;
}

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
})
export class EditConfigComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public account_id: string;
  public logo: Document;

  constructor(
    public dialogRef: MatDialogRef<EditConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfig,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public config: ConfigsService,
    public app: AppService,
    public dialogs: DialogsService,
    public translate: TranslateService,
    public events$: QEventsService
  ) {}

  // Workaround for angular component issue https://github.com/angular/components/issues/13870
  // Fixed from comment https://github.com/angular/components/issues/13870#issuecomment-502071712
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  ngOnInit() {
    this.account_id = this.data.account_id;
    this.logo = this.data.logo ? this.data.logo : null;

    const hasLocation = this.data.locations && this.data.locations.length > 0;
    const location: IRecogemeLocation = hasLocation
      ? this.data.locations[0]
      : {
          lat: null,
          lon: null,
          address: '',
        };

    this.form = this.formBuilder.group({
      bill_header: [this.data.bill_header],
      bill_footer: [this.data.bill_footer],
      company_name: [this.data.company_name],
      lat: [location.lat],
      lon: [location.lon],
      address: [location.address],
    });
  }

  /* istanbul ignore next */
  add() {
    this.setIsLoading(true);

    this.config
      .update(this.data.id, {
        bill_header: this.form.value.bill_header,
        bill_footer: this.form.value.bill_footer,
        company_name: this.form.value.company_name,
        logo: this.logo ? (('/logos/' + this.logo['id']) as any) : null,
        locations: [
          {
            lat: Number(this.form.value.lat),
            lon: Number(this.form.value.lon),
            address: this.form.value.address,
          },
        ],
        account_id: this.account_id,
      })
      .subscribe(
        (resp) => {
          this.snackbar.open('Configuracion actualizada');
          this.close(true);
          this.setIsLoading(false);
          this.events$.fire('account:changed');
        },
        (err) => {
          this.setIsLoading(false);
          this.snackbar.open(err.message);
        }
      );
  }

  logoPicked($event: Document) {
    this.logo = $event;
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
