import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteDialogStatus } from '@qbitartifacts/qbit-kit-ng';

export interface ConfirmationDialogData {
  title: string;
  subtitle: string;
  description: string;
  confirmButtonText: string;
}

@Component({
  selector: 'caste-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent {
  public title = 'DELETE_ITEM';
  public subtitle = 'DELETE_ITEM_CONFIRM';
  public description = 'DELETE_ITEM_CONFIRM_DESC';
  public confirmButtonText = 'DELETE';

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {
    this.setIfPresent(data, 'title');
    this.setIfPresent(data, 'subtitle');
    this.setIfPresent(data, 'description');
    this.setIfPresent(data, 'confirmButtonText');
  }

  private setIfPresent(data: any, key: string) {
    if (data[key] !== undefined) {
      this[key] = data[key];
    }
  }

  close(status: DeleteDialogStatus = DeleteDialogStatus.CANCEL) {
    this.dialogRef.close(status);
  }

  proceed() {
    this.close(DeleteDialogStatus.DELETE);
  }
}
