import { RatesService } from '../../../../services/CRUD/recogeme/rates.service';
import { Component, Input } from '@angular/core';
import { QApiSelectorComponent } from '@qbitartifacts/qbit-kit-ng';
import { Observable } from 'rxjs/internal/Observable';
import { Vehicle } from 'src/app/core/entities/vehicle';
import { of } from 'rxjs/internal/observable/of';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-type-selector',
  templateUrl: './vehicle-type-selector.component.html',
})
export class VehicleTypeSelector extends QApiSelectorComponent {
  label: string = 'SELECT_VEHICLE_TYPE';

  constructor(public rates$: RatesService, public translate: TranslateService) {
    super();
    this.item = Vehicle.VehicleTypes[0];
  }

  getSearchObservable(query: string): Observable<any> {
    return of(
      Vehicle.VehicleTypes.filter((t) => {
        const matches = t.toLowerCase().startsWith(query);
        const matchesTranslated = this.translate
          .instant(t)
          .toLowerCase()
          .startsWith(query);

        return matches || matchesTranslated;
      })
    );
  }
}
