import { environment } from 'src/environments/environment';
import {
  PermissionAdmin,
  PermissionUser,
} from '@qbitartifacts/caste-client-ng';
import { QSidemenuItem } from '@qbitartifacts/qbit-kit-ng';
import { PermissionUserAndBillingOfficer, PermissionUserAndOfficer } from '../core/permissions/permissions';

export const SIDEMENU_ITEMS: QSidemenuItem[] = [
  {
    name: 'dashboard',
    icon: 'dashboard',
    label: 'DASHBOARD',
    route: '/dashboard',
    permission: PermissionUser,
  },
  // Admin items after separator
  {
    name: 'admin_separator',
    separator: true,
    label: 'ADMIN',
    permission: PermissionAdmin,
  },
  {
    name: 'records',
    icon: 'receipt',
    label: 'RECORDS',
    route: '/records',
    permission: PermissionUser,
  },
  {
    name: 'vehicles',
    icon: 'directions_car',
    label: 'VEHICLES',
    route: '/vehicles',
    permission: PermissionUserAndOfficer,
  },
  {
    name: 'images',
    icon: 'perm_media',
    label: 'IMAGES',
    route: '/images',
    permission: PermissionAdmin,
  },
  {
    name: 'queries',
    icon: 'find_in_page',
    label: 'QUERIES',
    route: '/queries',
    permission: PermissionUser,
  },
  {
    name: 'rates',
    icon: 'attach_money',
    label: 'RATES',
    route: '/rates',
    permission: PermissionAdmin,
  },
  {
    name: 'bills',
    icon: 'receipt_long',
    label: 'BILLS',
    route: '/bills',
    permission: PermissionUserAndBillingOfficer,
  },
  // Superadmin
  {
    name: 'caste_users',
    icon: 'person',
    label: 'USERS',
    route:
      environment.caste.panelUrl + '/users?realm=' + environment.caste.realm,
    isExternal: true,
    permission: PermissionAdmin,
  },
  // Versions
  {
    name: 'version_label',
    separator: true,
    label: 'VERSION',
    permission: PermissionUser,
  },
  {
    name: 'panel_version',
    keyValue: true,
    label: 'PANEL',
    value: environment.version,
    permission: PermissionUser,
  },
];
