import { AfterContentInit, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CasteAuthService,
  LoginResponse
} from '@qbitartifacts/caste-client-ng';
import { QEventsService } from '@qbitartifacts/qbit-kit-ng';
import { LoadablePageComponent } from 'src/app/core/base/loadable.page';
import { PageBaseComponent } from 'src/app/core/base/page.base';
import { Session } from 'src/app/core/entities/session';
import { PublicGuard } from 'src/app/core/guards/public.guard';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';


const oneSecond = 1000;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  extends PageBaseComponent
  implements AfterContentInit, LoadablePageComponent
{
  static guards: any[] = [PublicGuard];
  public title = 'Login';
  public hidePass = true;
  public isLoading = false;
  public loginForm: FormGroup;
  public submitted = false;

  constructor(
    title: Title,
    private formBuilder: FormBuilder,
    public translate$: TranslateService,
    public auth$: AuthService,
    public casteAuth$: CasteAuthService,
    public user$: UserService,
    public events: QEventsService,
    public router: Router,
    public qbitAuth: CasteAuthService
  ) {
    super(title, translate$);
  }

  public ngAfterContentInit() {
    super.ngAfterContentInit();

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  public setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  public onSubmit(): boolean {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return false;
    }

    this.setIsLoading(true);

    this.qbitAuth
      .signIn({
        username: this.username.value,
        password: this.password.value,
      })
      .subscribe({
        next: this.loginSuccess.bind(this),
        error: this.loginError.bind(this),
      });

    return true;
  }

  private loginSuccess(resp: LoginResponse) {
    this.setIsLoading(false);

    const expireDateTimestampSec = new Date(resp.exp * oneSecond);
    const session = new Session().fromJson({
      token: resp.token,
      expireDate: expireDateTimestampSec,
    });
    const tokenData = session.getDecodedToken();

    this.user$.setUserFromTokenData(tokenData);
    this.user$.setApplication(tokenData.application);
    this.casteAuth$.saveToken(session.token);
    this.auth$.setSession(session);

    this.events.fire(AuthService.LOGIN_EVENT);

    this.navigateOnLogin();
  }

  private navigateOnLogin() {
    this.auth$.redirectUrl
      ? this.redirectToSavedUrl()
      : this.router.navigate(['/dashboard']);
  }

  private redirectToSavedUrl() {
    this.router.navigate([this.auth$.redirectUrl]);
    this.auth$.redirectUrl = null;
  }

  private loginError(error) {
    this.loginForm.setErrors({
      invalidCredentials: error.message,
    });
    this.setIsLoading(false);
  }
}
