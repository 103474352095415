import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { PageBaseComponent } from 'src/app/core/base/page.base';
import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
})
export class RatesComponent extends PageBaseComponent
  implements AfterContentInit {
  static guards: any[] = [AuthedGuard, AdminGuard];
  public title = 'RATES';

  constructor(title: Title, translate$: TranslateService) {
    super(title, translate$);
  }
}
