<!-- TODO: clean this page up -->
<div class="bootstrap-wrapper">
    <mat-card class="no-padding marg-bottom-20" *ngIf="itemData">
        <mat-card-header class="no-margin">
            <mat-card-title>
                <div class="marg-top-15">
                    <qbit-breadcrumbs></qbit-breadcrumbs>
                </div>
            </mat-card-title>

            <div class="abs-right-center flex-row center">
                <button *ngIf="user$.isAdmin() || user$.isSuperadmin() || isBillingOfficer" mat-icon-button
                    [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="itemData.signature && user$.isAdmin() || user$.isSuperadmin()" mat-menu-item
                        (click)="seeSignature(itemData)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">gesture</mat-icon>
                        <span>{{'VIEW_SIGNATURE'|translate}}</span>
                    </button>

                    <button *ngIf="itemData.bill && (user$.isAdmin() || user$.isSuperadmin() || isBillingOfficer)"
                        mat-menu-item routerLink="/bills" [queryParams]="{ 'record.id': itemData.id }">
                        <mat-icon class="mat-icon material-icons mat-icon-small">receipt_long</mat-icon>
                        <span>{{'BILLS'|translate}}</span>
                    </button>

                    <button *ngIf="itemData.bill && (user$.isAdmin() || user$.isSuperadmin() || isBillingOfficer)"
                        mat-menu-item (click)="editBill()">
                        <mat-icon class="mat-icon material-icons mat-icon-small">receipt_long</mat-icon>
                        <span>{{'EDIT_BILL'|translate}}</span>
                    </button>

                    <button *ngIf="!itemData.bill && (user$.isAdmin() || user$.isSuperadmin() || isBillingOfficer)"
                        mat-menu-item (click)="addBill()">
                        <mat-icon class="mat-icon material-icons mat-icon-small">receipt_long</mat-icon>
                        <span>{{'ADD_BILL'|translate}}</span>
                    </button>

                    <button *ngIf="user$.isAdmin() || user$.isSuperadmin()" mat-menu-item (click)="openEdit(itemData)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                        <span>{{'EDIT'|translate}}</span>
                    </button>

                    <button *ngIf="user$.isAdmin() || user$.isSuperadmin()" mat-menu-item
                        (click)="openEditVehicle(itemData.vehicle)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                        <span>{{'EDIT_VEHICLE'|translate}}</span>
                    </button>
                </mat-menu>
            </div>
        </mat-card-header>

        <mat-card-content class="no-padding">
            <mat-tab-group>
                <mat-tab label="{{'DETAILS' | translate}}">
                    <div class="row no-margin">
                        <div class="col-xlg-6 col-lg-6 col-md-12 col-sm-12 marg-bottom-20">
                            <div class="padd-20">
                                <b class="title bold">{{'INFO' | translate}}</b>

                                <mat-list role="list">
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'INTERNAL_ID'| translate}}
                                        </span>
                                        <code class="text-small marg-left-10">
                                                {{itemData && itemData.internal_id}}
                                            </code>
                                    </mat-list-item>
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'LICENSE_PLATE'| translate}}
                                        </span>
                                        <span class="text-small marg-left-10">
                                            {{itemData && itemData.vehicle ? itemData.vehicle.license_plate: '...'}}
                                        </span>
                                    </mat-list-item>
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'DISPLACEMENT_TYPE'| translate}}
                                        </span>
                                        <span class="text-small marg-left-10">
                                            {{itemData ? itemData.displacement_type: '...'}}
                                        </span>
                                    </mat-list-item>
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'OBSERVATIONS'| translate}}
                                        </span>
                                        <span class="text-small marg-left-10">
                                            {{itemData ? itemData.observations: '...'}}
                                        </span>
                                    </mat-list-item>
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'ORIGIN_LOCATION'| translate}}
                                        </span>
                                        <span class="text-small marg-left-10">
                                            {{itemData && itemData.origin_location ? itemData.origin_location.address:
                                            '...'}}
                                        </span>
                                    </mat-list-item>
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'DESTINY_LOCATION'| translate}}
                                        </span>
                                        <span class="text-small marg-left-10">
                                            {{itemData && itemData.destiny_location ? itemData.destiny_location.address:
                                            '...'}}
                                        </span>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </div>
                        <div class="col-xlg-6 col-lg-6 col-md-12 col-sm-12 marg-bottom-20">
                            <div class="padd-20">
                                <b class="title bold">{{'DETAILS' | translate}}</b>
                                <mat-list role="list">
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'USER'| translate}}
                                        </span>
                                        <a [href]="environment.caste.panelUrl + '/users/' + driver.id" target="_blank"
                                            *ngIf="itemData.driver | async as driver">
                                            <qbit-chip-link tooltip="{{'USER' | translate}}" [label]="driver.username"
                                                [icon]="'person'">
                                            </qbit-chip-link>
                                        </a>
                                    </mat-list-item>

                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'DRIVER'| translate}}
                                        </span>
                                        <qbit-chip tooltip="{{'DRIVER'|translate}}"
                                            [label]="itemData && itemData.user ? itemData.user.data.n_conductor: 'n/d'">
                                        </qbit-chip>
                                    </mat-list-item>

                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'TRUCK'| translate}}
                                        </span>
                                        <qbit-chip tooltip="{{'TRUCK'|translate}}"
                                            [label]="itemData && itemData.user ? itemData.user.data.n_grua: 'n/d'">
                                        </qbit-chip>
                                    </mat-list-item>

                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'VEHICLE'| translate}}
                                        </span>
                                        <app-vehicle-chip *ngIf="itemData && itemData.vehicle"
                                            [vehicle]="itemData.vehicle">
                                        </app-vehicle-chip>
                                    </mat-list-item>
                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'STATUS'| translate}}
                                        </span>
                                        <qbit-chip tooltip="{{'STATUS'|translate}}"
                                            [label]="itemData ? itemData.status: '...'"
                                            [color]="itemData ? itemData.status: 'default'"></qbit-chip>
                                    </mat-list-item>


                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'IN_SCRAPPING'| translate}}
                                        </span>
                                        <mat-icon *ngIf="itemData && itemData.in_scrapping">check</mat-icon>
                                        <mat-icon *ngIf="itemData && !itemData.in_scrapping">close</mat-icon>
                                    </mat-list-item>

                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'INMOBILIZED'| translate}}
                                        </span>
                                        <mat-icon *ngIf="itemData && itemData.inmobilized">check</mat-icon>
                                        <mat-icon *ngIf="itemData && !itemData.inmobilized">close</mat-icon>
                                    </mat-list-item>

                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'CHARGED_ON_ROUTE'| translate}}
                                        </span>
                                        <mat-icon *ngIf="itemData && itemData.charged_on_route">check</mat-icon>
                                        <mat-icon *ngIf="itemData && !itemData.charged_on_route">close</mat-icon>
                                    </mat-list-item>

                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'STARTED_AT' | translate}}
                                        </span>
                                        <span class="text-small marg-left-10">
                                            <i>{{itemData.started_at | date: 'medium'}}</i>
                                        </span>
                                    </mat-list-item>

                                    <mat-list-item role="listitem" class="flex-row center">
                                        <span class="text-mutted" style="min-width: 100px">
                                            {{'FINISHED_AT' | translate}}
                                        </span>
                                        <span class="text-small marg-left-10">
                                            <i>{{itemData.finished_at | date: 'medium'}}</i>
                                        </span>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="{{'IMAGES' | translate}}">
                    <div class="padd-20 flex-row centered" style="height: 300px">
                        <app-custom-slider *ngIf="itemData.images && itemData.images.length > 0">
                            <cdk-step *ngFor="let image of itemData.images">
                                <a class="image-holder" [href]="image.src" target="_blank">
                                    <img height="200" [src]="image.src" />
                                </a>
                            </cdk-step>
                        </app-custom-slider>

                        <div *ngIf="!itemData.images || itemData.images.length <= 0">
                            <p>{{'NO_IMAGES_ATTACHED'|translate}}</p>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="{{'DOCUMENTS' | translate}}">
                    <div class="padd-20 flex-row centered" style="height: 300px">
                        <app-custom-slider (onAddDocument)="addDocument()" [showAddButton]="true"
                            *ngIf="itemData.documents && itemData.documents.length > 0">
                            <cdk-step *ngFor="let document of itemData.documents">
                                <a class="document-holder flex-row centered" [href]="document.src" target="_blank">
                                    <img *ngIf="document.isImage" height="200" style="border-radius: 6px"
                                        [src]="document.src" />
                                    <div *ngIf="!document.isImage"
                                        style="height: 200px; width: 200px; background: #00000038; border-radius: 6px;"
                                        class="column centered">
                                        <span class="text-mutted col-white"
                                            style="text-align: center;">{{document.name}}</span>
                                        <h4 class="col-white">{{document.extension}}</h4>
                                    </div>
                                </a>
                            </cdk-step>
                        </app-custom-slider>

                        <div class="column centered" *ngIf="!itemData.documents || itemData.documents.length <= 0">
                            <p>{{'NO_DOCUMENTS_ATTACHED'|translate}}</p>
                            <button mat-raised-button color="primary" (click)="addDocument()">
                                {{'ADD_DOCUMENT'| translate}}
                            </button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>

    <app-queries-list *ngIf="itemData" [showBreadcrumbs]="false" [itemQueries]="itemData.queries"></app-queries-list>
</div>