import { Env } from '../../../../environments/environment';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { QSidemenuService } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  public environment: Env = environment;
  constructor(public sidemenu: QSidemenuService) {}

  public toggleSidemenu() {
    this.sidemenu.toggle();
  }
}
