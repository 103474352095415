import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { PageBaseComponent } from 'src/app/core/base/page.base';
import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';
import { ConfigsService } from 'src/app/services/CRUD/recogeme/configs.service';
import { UserService } from 'src/app/services/user.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
})
export class BillsComponent
  extends PageBaseComponent
  implements AfterContentInit
{
  static guards: any[] = [AuthedGuard];
  public title = 'BILLS';

  constructor(
    title: Title,
    translate$: TranslateService,
    private router: Router,
    private user$: UserService,
    private config$: ConfigsService,
    private dialogs$: DialogsService
  ) {
    super(title, translate$);
  }

  ngOnInit() {
    this.checkConfig();
  }

  /* istanbul ignore next */
  private async checkConfig() {
    const hasConfig = await this.config$.doesAccountHaveConfig(
      this.user$.getAccountId()
    );

    if (!hasConfig) {
      this.dialogs$
        .openCreateConfig(
          {
            account_id: this.user$.getAccountId(),
          },
          { disableClose: true }
        )
        .afterClosed()
        .subscribe((resp) => {
          if (!resp) this.router.navigate(['/']);
        });
    }
  }
}
