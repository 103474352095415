import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRecord, Record } from 'src/app/core/entities/record';
import { CrudBaseService } from 'src/app/core/base/crud.base.service';
import {
  CasteAccountsService,
  CasteUsersService,
} from '@qbitartifacts/caste-client-ng';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecordsService extends CrudBaseService<IRecord> {
  constructor(
    http: HttpClient,
    auth: AuthService,
    public users$: CasteUsersService,
    public accounts$: CasteAccountsService
  ) {
    super(
      {
        endpoint: 'records',
      },
      http,
      auth
    );
  }

  public queryParams;

  public getOne(id: string, userType?: string): Observable<IRecord> {
    return super.getOne(id, userType).pipe(
      map((el) => {
        el.driver = this.users$.getOne(el.user_id, 'user');
        return el;
      })
    );
  }

  public listAll(
    params?: { [key: string]: string },
    userType?: string
  ): Observable<IRecord[]> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );

    this.queryParams = params;
    
    return this.get<IRecord[]>(path, params).pipe(
      map((resp: any) => {
        resp.data = resp.data.map((el) => {
          el.user = this.users$.getOne(el.user_id, 'user');
          el.account = this.accounts$.getOne(el.account_id, 'admin');
          el.started_at = new Date(el.started_at).getTime();
          return el;
        });
        return resp;
      })
    );
  }

    public reportAP(
        userType?: string
    ) {
        const path: string = this.createPathFromParts(
            userType,
            `/Reports/ReportAP`
        );

        this.get<any>(path, this.queryParams).subscribe(data => {
            
            const exelBlob = this.dataURItoBlob(data.excel);
            const url = window.URL.createObjectURL(exelBlob);
            
            window.open(url);

        })
    }

    public reportEN(
        userType?: string
    ) {
        const path: string = this.createPathFromParts(
            userType,
            `/Reports/ReportEN`
        );

        this.get<any>(path, this.queryParams).subscribe(data => {
            
            const exelBlob = this.dataURItoBlob(data.excel);
            const url = window.URL.createObjectURL(exelBlob);
            
            window.open(url);

        })
    }

    public reportENBill(
        userType?: string
    ) {
        const path: string = this.createPathFromParts(
            userType,
            `/Reports/ReportENBill`
        );

        this.get<any>(path, this.queryParams).subscribe(data => {
            
            const exelBlob = this.dataURItoBlob(data.excel);
            const url = window.URL.createObjectURL(exelBlob);
            
            window.open(url);

        })
    }

    public mapItem(item: IRecord) {
        return new Record().fromJson(item);
    }

    private dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    
        
        return blob;
     }
}
