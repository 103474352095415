<div class="bootstrap-wrapper padd-v-20">
    <div class="row">
        <div class="col-xlg-12 col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 10px;">
            <mat-card class="no-padding marg-bottom-20" *ngIf="itemData">
                <mat-card-header class="no-margin">
                    <mat-card-title>
                        <div class="marg-top-15">
                            <qbit-breadcrumbs></qbit-breadcrumbs>
                        </div>
                    </mat-card-title>

                    <div class="abs-right-center flex-row center">
                        <div class="marg-left-10 row center h v">
                            <qbit-chip-link link="/records/{{itemData.records[0].id}}" icon="receipt" *ngIf="itemData"
                                [label]="itemData.records[0].internal_id">
                            </qbit-chip-link>

                            <button *ngIf="user$.isAdmin() || user$.isSuperadmin()" mat-icon-button
                                [matMenuTriggerFor]="menu" aria-label="Item options menu"
                                (click)="$event.preventDefault(); $event.stopPropagation()">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openEdit(itemData)">
                                    <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                                    <span>{{'EDIT'|translate}}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content class="padd-15">
                    <div class="row">
                        <div class="col-xlg-6 col-lg-6 col-md-12 col-sm-12">
                            <b class="title bold marg-bottom-10">{{'DETAILS' | translate}}</b>
                            <div id="divDetails" class="marg-left-10 marg-top-15">
                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'LICENSE_PLATE'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.license_plate: '...'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'TYPE'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10" matTooltip="{{itemData.type|translate}}">
                                        <vehicle-type-icon [type]="itemData.type"></vehicle-type-icon>
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'BRAND'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.brand: '...'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'MAKE'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.make: '...'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'COLOR'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.color: '...'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'TUNNING'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        <mat-icon *ngIf="itemData && itemData.tunning" class="col-green">check
                                        </mat-icon>
                                        <mat-icon *ngIf="itemData && !itemData.tunning" class="col-red">close</mat-icon>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-xlg-6 col-lg-6 col-md-12 col-sm-12">
                            <b class="title bold marg-bottom-10">{{'STATE' | translate}}</b>
                            <div class="marg-left-10 marg-top-15">
                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'GENERAL'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.general: '------'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'CHAPA'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.chapa: '------'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'RIMS'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData && itemData.rims ? itemData.rims: '------'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'DOORS'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.doors: '------'}}
                                    </span>
                                </div>


                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'WINDOWS'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.windows: '------'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'LIGHTS'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.lights: '------'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'INDICATORS'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.indicators: '------'}}
                                    </span>
                                </div>

                                <div class="row center marg-left-15 marg-bottom-10">
                                    <span class="text-mutted" style="width: 100px">
                                        {{'REAR_MIRRORS'| translate}}
                                    </span>
                                    <span class="text-small marg-left-10">
                                        {{itemData ? itemData.rear_mirrors: '------'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>