import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { XHR } from './xhr';

@Injectable()
export class FileUploaderService {
  public uploadprogress$: Observable<any>;
  public progressObserver;

  constructor(private readonly xhr: XHR, private readonly auth: AuthService) {
    this.uploadprogress$ = new Observable(
      (observer) => (this.progressObserver = observer)
    );
  }

  public uploadFile(url: string, file: string | Blob): Observable<any> {
    const form = new FormData();
    form.append('file', file);

    const accessToken = this.auth.session.token;

    return this.xhr.call({
      url: url,
      method: 'POST',
      data: form,
      authorization: `Bearer ${accessToken}`,
      onProgress: this.progressObserver,
    });
  }
}
