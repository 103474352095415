<qbit-dialog-header title="{{'EDIT_RECORD' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="edit()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{'EDIT_RECORD' | translate}}
                </h4>
                <p class="text-mutted marg-bottom-15">{{'EDIT_RECORD_DESC' | translate}}</p>

                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'STARTED_DATE'|translate}}</mat-label>
                            <input matInput type="datetime-local" formControlName="started_at">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'FINISHED_DATE'|translate}}</mat-label>
                            <input matInput type="datetime-local" formControlName="finished_at">
                        </mat-form-field>
                    </div>


                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'ORIGIN_LOCATION'|translate}}</mat-label>
                            <input matInput type="string" formControlName="origin_location">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'DESTINY_LOCATION'|translate}}</mat-label>
                            <input matInput type="string" formControlName="destiny_location">
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field>
                            <mat-label>{{'DISPLACEMENT' | translate}}</mat-label>
                            <mat-select formControlName="displacement_type">
                                <mat-option *ngFor="let type of diplacementTypes" [value]="type">
                                    {{type|translate}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="displacement_type.invalid && (displacement_type.dirty) && displacement_type.errors.required">
                                {{'TYPE_REQUIRED' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12">
                        <mat-form-field>
                            <mat-label>{{'OBSERVATIONS'|translate}}</mat-label>
                            <textarea matInput type="string" formControlName="observations"></textarea>
                        </mat-form-field>
                    </div>


                    <div class="col-12">
                        <div class="row">
                            <div class="col-4">
                                <div style="height: 100%; display: flex; align-items: center">
                                    <mat-checkbox color="primary" formControlName="in_scrapping"
                                        [matTooltip]="'IN_SCRAPPING_DESC' | translate">{{'IN_SCRAPPING' | translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-4">
                                <div style="height: 100%; display: flex; align-items: center">
                                    <mat-checkbox color="primary" formControlName="charged_on_route"
                                        [matTooltip]="'CHARGED_ON_ROUTE_DESC' | translate">{{'CHARGED_ON_ROUTE' | translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-4">
                                <div style="height: 100%; display: flex; align-items: center">
                                    <mat-checkbox color="primary" formControlName="inmobilized"
                                        [matTooltip]="'INMOBILIZED_DESC' | translate">{{'INMOBILIZED' | translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [disabled]="form.invalid || isLoading || !form.dirty" [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>