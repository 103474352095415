import { PathResolveService } from './services/path-resolve.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './config/routes';
import { QNotFoundComponent } from '@qbitartifacts/qbit-kit-ng';

@NgModule({
  imports: [
    RouterModule.forRoot([
      ...routes,
      {
        path: '**',
        resolve: {
          path: PathResolveService,
        },
        component: QNotFoundComponent,
      },
    ]),
  ],
  exports: [RouterModule],
  providers: [PathResolveService],
})
export class AppRoutingModule {}
