import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vehicle-type-icon',
  templateUrl: './vehicle-type-icon.component.html',
  styleUrls: ['./vehicle-type-icon.component.scss'],
})
export class VehicleTypeIconComponent implements OnInit {
  @Input() type: string;
  icon: string;

  ngOnInit() {
    this.icon = VehicleTypeIconComponent.getVehicleIcon(this.type);
  }

  public static getVehicleIcon(type: string) {
    switch (type) {
      case 'car':
        return 'directions_car';
      case 'bicicle':
        return 'directions_bike';
      case 'ciclomotor':
      case 'motorbike':
        return 'motorcycle';
      default:
        return 'directions_car';
    }
  }
}
