<mat-card class="no-padding">
    <qbit-table-header title="BILLS" [isLoading]="isLoading" [options]="{'showBreadcrumbs': showBreadcrumbs}">
        <button class="marg-left-10" mat-raised-button color="primary" (click)="addNewBill()">
            {{'ADD_BILL' | translate}}
        </button>

        <button class="marg-left-10" mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters-addable *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        [autocomplete]="autocompleteData" (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams">
    </qbit-table-filters-addable>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NUMBER'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <code>{{row.number}}</code></td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <code>{{row.name}}</code></td>
        </ng-container>

        <ng-container matColumnDef="last_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'LASTNAME'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <code>{{row.last_name}}</code></td>
        </ng-container>

        <ng-container matColumnDef="record">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'RECORD'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <app-record-chip *ngIf="row.record" [record]="row.record"></app-record-chip>
            </td>
        </ng-container>

        <ng-container matColumnDef="vehicle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'VEHICLE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <app-vehicle-chip *ngIf="row.record.vehicle" [vehicle]="row.record.vehicle"></app-vehicle-chip>
            </td>
        </ng-container>

        <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'RATE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <app-rate-chip *ngIf="row.rate" [rate]="row.rate"></app-rate-chip>
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'AMOUNT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.amount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATUS'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span class="status-{{row.status}}">
                    {{row.status|translate}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CREATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <i>{{row.created_at | date: 'medium'}}</i></td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="row.pdf" mat-menu-item (click)="viewBillPdf(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">picture_as_pdf</mat-icon>
                        <span>{{'VIEW_PDF'|translate}}</span>
                    </button>

                    <button mat-menu-item (click)="editBill(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                        <span>{{'EDIT'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>


        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>