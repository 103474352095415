import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { RatesService } from 'src/app/services/CRUD/recogeme/rates.service';
import { CreateRate } from 'src/app/core/entities/rate';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-create-rate',
  templateUrl: './create-rate.component.html',
})
export class CreateRateComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public rateData: CreateRate = {
    name: '',
    vehicle_type: 'car',
    day_price: 0,
    hour_price: 0,
    base_price_after_unhooking: 0,
    base_price_before_unhooking: 0,
    min_strip_minutes: 0,
    max_days: 0,
    max_hours_per_day: 0,
    days_hourly_billing_allowed: 0,
  };

  constructor(
    public dialogRef: MatDialogRef<CreateRateComponent>,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public service: RatesService,
    public app: AppService,
  ) {}

  // Workaround for angular component issue https://github.com/angular/components/issues/13870
  // Fixed from comment https://github.com/angular/components/issues/13870#issuecomment-502071712
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  /* istanbul ignore next */
  edit() {
    if (!this.form.dirty || this.isLoading) return;

    this.setIsLoading(true);

    const data = {
      ...this.form.value,
      vehicle_type: this.rateData.vehicle_type,
      account_id: this.app.getOwner(),
    };

    this.service.create(data).subscribe(
      (resp) => {
        this.snackbar.open('Rate creada');
        this.close(true);
        this.setIsLoading(false);
      },
      (err) => {
        this.setIsLoading(false);
        this.snackbar.open(err.message);
      }
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      day_price: [0, Validators.required],
      hour_price: [0, Validators.required],
      base_price_after_unhooking: [0, Validators.required],
      base_price_before_unhooking: [0, Validators.required],
      min_strip_minutes: [0, Validators.required],
      max_days: [0, Validators.required],
      max_hours_per_day: [0, Validators.required],
      days_hourly_billing_allowed: [0, Validators.required],
    });
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
