import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteDialogStatus } from '@qbitartifacts/qbit-kit-ng';

export interface ImagePreviewData {
  title: string;
  subtitle: string;
  imageUrl: string;
}

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
})
export class ImagePreviewComponent {
  public title: string;
  public subtitle: string;
  public imageUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImagePreviewData
  ) {
    this.setIfPresent(data, 'title');
    this.setIfPresent(data, 'subtitle');
    this.setIfPresent(data, 'imageUrl');
  }

  private setIfPresent(data: any, key: string) {
    if (data[key] !== undefined) {
      this[key] = data[key];
    }
  }

  close(status: DeleteDialogStatus = DeleteDialogStatus.CANCEL) {
    this.dialogRef.close(status);
  }

  proceed() {
    this.close(DeleteDialogStatus.DELETE);
  }
}
