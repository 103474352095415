import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CasteAuthModule } from '@qbitartifacts/caste-client-ng';
import {
  QBIT_REQ_CACHE_MAX_AGE,
  QSidemenuModule,
} from '@qbitartifacts/qbit-kit-ng';
import { AvatarModule } from 'ngx-avatar';
import { AppRoutingModule } from './routing.module';
import { qbitLocalesProvider } from './config/locales';
import { ItemPageBaseComponent } from './core/base/item.page.base';
import { PageBaseComponent } from './core/base/page.base';
import { AppComponent } from './core/components/app/app.component';
import { ComponentsModule } from './core/components/components.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { PagesModule } from './pages/pages.module';
import { PROVIDERS } from './services';
import { SharedModule } from './shared.module';
import { TranslationsModule } from './translations.module';
import { ServicesModule } from './services.module';
import { RECOGEME_REQ_CACHE_MAX_AGE } from './config/consts';

registerLocaleData(localeEn);
registerLocaleData(localeEs);

const modules = [
  BrowserAnimationsModule,
  ReactiveFormsModule,
  HttpClientModule,
  TranslationsModule,
  ComponentsModule,
  AvatarModule.forRoot(),

  // Our modules
  AppRoutingModule,
  SharedModule,
  CasteAuthModule,
  PagesModule,
  DialogsModule,
  QSidemenuModule,
  ServicesModule,
];

@NgModule({
  declarations: [
    AppComponent,
    PageBaseComponent as any,
    ItemPageBaseComponent as any,
  ],
  imports: modules,
  providers: [
    qbitLocalesProvider,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: QBIT_REQ_CACHE_MAX_AGE, useValue: RECOGEME_REQ_CACHE_MAX_AGE },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
