import { Component, OnInit } from '@angular/core';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { AuthService } from 'src/app/services/auth.service';
import { AppService } from 'src/app/services/app.service';
import { UserService } from '../../../services/user.service';
import { ConfigsService } from 'src/app/services/CRUD/recogeme/configs.service';
import { QEventsService } from '@qbitartifacts/qbit-kit-ng';
import { DialogsService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'app-user-avatar-menu',
  templateUrl: './user-avatar-menu.component.html',
  styleUrls: ['./user-avatar-menu.component.scss'],
})
export class UserAvatarMenuComponent implements OnInit {
  public adminPermission = PermissionAdmin;
  public config;

  constructor(
    public user$: UserService,
    public auth$: AuthService,
    public app$: AppService,
    public dialogs$: DialogsService,
    public events$: QEventsService,
    public config$: ConfigsService
  ) {
    events$.on('account:changed').subscribe(() => {
      this.ngOnInit();
    });
  }

  /* istanbul ignore next */
  public changeDarkTheme($event) {
    this.app$.setDarkThemeEnabled($event.checked);
  }

  ngOnInit() {
    this.loadConfig().then((data) => {
      this.config = data;
    });
  }

  loadConfig() {
    return this.config$
      .getAccountConfig(this.user$.getAccountId())
      .then((data) => data[0]);
  }

  createConfig() {
    this.dialogs$.openCreateConfig({ account_id: this.app$.getOwner() });
  }

  editConfig() {
    this.loadConfig().then((config) => {
      this.dialogs$.openEditConfig(config);
    });
  }

  public logout() {
    this.auth$.logout();
  }
}
