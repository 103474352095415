import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CASTE_AUTH_CONFIG } from '@qbitartifacts/caste-client-ng';
import {
  QBIT_SIDEMENU_ITEMS,
  QEventsService,
  RequestCacheService
} from '@qbitartifacts/qbit-kit-ng';
import { environment } from 'src/environments/environment';
import { SIDEMENU_ITEMS } from '../config/sidemenu.items';
import { AuthedGuard } from '../core/guards/authed.guard';
import { PublicGuard } from '../core/guards/public.guard';
import { CustomMatPaginatorIntl } from '../core/md-i18n';
import { BillsService } from './CRUD/recogeme/bills.service';
import { ConfigsService } from './CRUD/recogeme/configs.service';
import { DocumentService } from './CRUD/recogeme/documents.service';
import { LogoService } from './CRUD/recogeme/logo.service';
import { MediaService } from './CRUD/recogeme/media.service';
import { QueriesService } from './CRUD/recogeme/queries.service';
import { RatesService } from './CRUD/recogeme/rates.service';
import { RecordsService } from './CRUD/recogeme/records.service';
import { VehiclesService } from './CRUD/recogeme/vehicles.service';
import { DashboardService } from './dashboard.service';
import { DialogsService } from './dialogs.service';
import { FileUploaderService } from './file-uploader.service';
import { CasteAccountsServiceOverride } from './overrides/caste-accounts.service';
import { CasteUsersServiceOverride } from './overrides/caste-users.service';
import { SessionCheckerService } from './session-checker.service';
import { XHR } from './xhr';

const qbitAuthConfigProvider = {
  provide: CASTE_AUTH_CONFIG,
  useValue: environment.caste,
};

export const PROVIDERS = [
  PublicGuard,
  AuthedGuard,
  QEventsService,
  SessionCheckerService,
  DialogsService,
  RecordsService,
  MediaService,
  QueriesService,
  VehiclesService,
  RatesService,
  BillsService,
  ConfigsService,
  DocumentService,
  CasteUsersServiceOverride.getProvider(),
  CasteAccountsServiceOverride.getProvider(),
  RequestCacheService,
  FileUploaderService,
  DashboardService,
  LogoService,
  XHR,
  qbitAuthConfigProvider,

  { provide: MAT_DIALOG_DATA, useValue: {} },
  {
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl,
  },
  {
    provide: QBIT_SIDEMENU_ITEMS,
    useValue: SIDEMENU_ITEMS,
  },
];
