import { RecogemeGrants } from '../../config/grants';
import {
  CasteUserService,
  InternalPermission,
  RoleAdmin,
  RoleSuperadmin,
  RoleUser,
  User,
} from '@qbitartifacts/caste-client-ng';
import { AppService } from 'src/app/services/app.service';
import { Inject } from '@angular/core';

export class UserAndBillingOfficerPermission extends InternalPermission {
  @Inject(AppService) public app$: AppService;

  constructor(subPermissions: InternalPermission[]) {
    super('user', subPermissions);
  }

  public canActivate(user: User, user$: CasteUserService) {
    if (!user) {
      return false;
    }

    const superActivate = super.canActivate(user, user$);
    const userIsRoleUser = user.hasRole(RoleUser);
    const userIsRoleAdmin = user.hasRole(RoleAdmin);
    const userIsRoleSuperAdmin = user.hasRole(RoleSuperadmin);

    const isBillingOfficer = user.hasPermissionForAccount(
      user$.getAccountId(),
      RecogemeGrants.BILLING_OFFICER
    );

    return (
      superActivate &&
      ((userIsRoleUser && isBillingOfficer) ||
        userIsRoleAdmin ||
        userIsRoleSuperAdmin)
    );
  }
}
