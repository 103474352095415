import { Bill } from 'src/app/core/entities/bill';
import { Component, OnInit } from '@angular/core';
import { StatusSelector } from './status-selector.component';

@Component({
  selector: 'app-bill-status-selector',
  templateUrl: './status-selector.component.html',
})
export class BillStatusSelector extends StatusSelector implements OnInit {
  ngOnInit() {
    this.items = Bill.SELECTABLE_STATUSES;
  }
}
