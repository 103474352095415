import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';
import { Vehicle } from 'src/app/core/entities/vehicle';
import { VehicleDamage } from 'src/app/core/entities/vehicle-damage';
import { VehiclesService } from 'src/app/services/CRUD/recogeme/vehicles.service';

@Component({
  selector: 'app-edit-vehicle',
  templateUrl: './edit-vehicle.component.html',
})
export class EditVehicleComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public vehicleTypes = Vehicle.VehicleTypes;
  public damageOptions1 = VehicleDamage.damageOptions1;
  public damageOptions2 = VehicleDamage.damageOptions2;
  public damageOptions3 = VehicleDamage.damageOptions3;
  public damageOptions4 = VehicleDamage.damageOptions4;
  public damageOptionsRims = VehicleDamage.damageOptionsRims;
  public damageCristales = VehicleDamage.damageCristales;

  public tunning: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Vehicle,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public vehiclesCrud: VehiclesService
  ) {
  }

  // Workaround for angular component issue https://github.com/angular/components/issues/13870
  // Fixed from comment https://github.com/angular/components/issues/13870#issuecomment-502071712
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  /* istanbul ignore next */
  edit() {
    this.setIsLoading(true);
    const data = {
      ...this.form.value,
      tunning: this.tunning,
    };

    this.vehiclesCrud.update(this.data.id, data).subscribe(
      (resp) => {
        this.snackbar.open('Vehiculo editado');
        this.close(true);
        this.setIsLoading(false);
      },
      (err) => {
        this.setIsLoading(false);
        this.snackbar.open(err.message);
      }
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      license_plate: [this.data.license_plate, Validators.required],
      type: [this.data.type],
      make: [this.data.make],
      brand: [this.data.brand],
      color: [this.data.color],
      // damage
      chapa: [this.data.chapa],
      doors: [this.data.doors],
      general: [this.data.general],
      indicators: [this.data.indicators],
      lights: [this.data.lights],
      rear_mirrors: [this.data.rear_mirrors],
      windows: [this.data.windows],
      rims: [this.data.rims],
    });
  }

  get license_plate() {
    return this.form.get('license_plate');
  }

  get type() {
    return this.form.get('type');
  }

  get make() {
    return this.form.get('make');
  }

  get brand() {
    return this.form.get('brand');
  }

  get color() {
    return this.form.get('color');
  }

  get chapa() {
    return this.form.get('chapa');
  }

  get doors() {
    return this.form.get('doors');
  }

  get general() {
    return this.form.get('general');
  }

  get indicators() {
    return this.form.get('indicators');
  }
  get lights() {
    return this.form.get('lights');
  }
  get rear_mirrors() {
    return this.form.get('rear_mirrors');
  }

  get windows() {
    return this.form.get('windows');
  }

  get rims() {
    return this.form.get('rims');
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
