<qbit-dialog-header title="{{'CREATE_BILL' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="edit()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-mutted marg-bottom-15" style="margin-top:0!important">
                    {{'CREATE_BILL_DESC' | translate}}
                </p>

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <app-record-selector [disabled]="disableRecordSelector"
                            [filters]="recordSelectorFilters" [(item)]="record">
                        </app-record-selector>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <app-rate-selector label="RATE" [filters]="rateSelectorFilters" [(item)]="rate">
                        </app-rate-selector>
                    </div>
                </div>

                <div class="row marg-top-15"
                    *ngIf="record && !record.inmobilized_documentation_presented && record.displacement_type == 'EN' && record.inmobilized">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <p class="marg-top-15" style="margin-top:0!important">
                            {{'DOCUMENTS_EN' | translate}}
                        </p>
                        <p class="text-mutted marg-top-15" style="margin-top:0!important">
                            {{'DOCUMENTS_EN_DESC' | translate}}
                        </p>

                        <button mat-raised-button type="button" color="primary" (click)="addDocument()">
                            {{'ADD_DOCUMENT'| translate}}
                        </button>
                    </div>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [disabled]="isLoading || !record || !rate" [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>