import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogsService } from 'src/app/services/dialogs.service';
import { Document } from 'src/app/core/entities/document';

@Component({
  selector: 'app-logo-picker',
  templateUrl: './logo-picker.component.html',
})
export class LogoPickerComponent {
  @Input() logo: Document;
  @Input() label = 'LOGO';
  @Input() noImageSrc = '../assets/no-image.png';
  @Output() onLogoPicked: EventEmitter<Document> = new EventEmitter();

  constructor(private dialogs: DialogsService) {}

  add() {
    this.dialogs
      .openAddLogo()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.onLogoPicked.emit(resp);
        }
      });
  }
}
