import { environment } from 'src/environments/environment';

export interface IMedia {
  content_url: string;
  file: string;
  file_path: string;
  src?: string;
  id: string;
  created_at: string;
  updated_at: string;
}

export class Media implements IMedia {
  content_url: string;
  file: string;
  file_path: string;
  id: string;
  created_at: string;
  updated_at: string;

  fromJson(obj: IMedia): Media {
    const record = new Media();
    // tslint:disable-next-line: forin
    for (const key in obj) {
      record[key] = obj[key];
    }
    return record;
  }

  get src() {
    return `${environment.url}${this.content_url}`;
  }
}
