export class VehicleDamage {
  public static damageOptions1 = ['------', 'Bueno', 'Abandono', 'Accidente'];
  public static damageOptions2 = [
    '------',
    'Raspaduras',
    'Abolladuras',
    'Raspaduras y Abolladuras',
  ];
  public static damageOptions3 = ['------', 'Cerradas', 'Abiertas', 'Forzadas'];
  public static damageOptions4 = ['------', 'Roto/s'];
  public static damageOptions5 = [
    '------',
    'Mal Estado',
    'Pinchadas',
  ];
  public static damageOptionsRims = [
    '------',
    'Raspaduras',
    'Abolladura',
    'Raspaduras y Abolladuras',
  ];

  public static damageCristales = [...VehicleDamage.damageOptions4, 'Bajados'];
}
