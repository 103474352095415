<qbit-dialog-header title="{{title | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <div class="padd-20">
        <p *ngIf="subtitle" class="subhead">{{subtitle | translate}}</p>

        <a [href]="imageUrl" target="_blank">
            <img mat-card-image [src]="imageUrl" alt="Photo de la firma del agente">
        </a>
    </div>
</div>