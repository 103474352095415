import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { CrudBaseService } from 'src/app/core/base/crud.base.service';
import { IMedia, Media } from 'src/app/core/entities/media';
import { FileUploaderService } from '../../file-uploader.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaService extends CrudBaseService<IMedia> {
  constructor(
    http: HttpClient,
    auth: AuthService,
    private readonly fileUploader: FileUploaderService
  ) {
    super(
      {
        endpoint: 'media',
      },
      http,
      auth
    );
  }

  get progress() {
    return this.fileUploader.uploadprogress$;
  }

  uploadFile(file: string | Blob) {
    const path = `${environment.url}/${this.opts.endpoint}`;
    return this.fileUploader.uploadFile(path, file);
  }

  public mapItem(item: IMedia) {
    return new Media().fromJson(item);
  }
}
