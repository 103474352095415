<div class="bootstrap-wrapper padd-v-20 padd-h-20 ">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12" style="padding: 0">
            <div class="row">
                <div class="col-lg-12">
                    <h4 class="title">
                        {{'ACCOUNT_INFO'|translate}} (<code>{{user$.selectedAccount?.name}}</code>)
                    </h4>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                    <mat-card routerLink="/records" class="hoverable">
                        <app-aggregate icon="receipt" title="RECORDS" [value]="dashboardService.recordsCount| async"
                            [valueColor]="'blue'">
                        </app-aggregate>
                    </mat-card>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12" *appPermissions="showVehiclesPermission">
                    <mat-card routerLink="/vehicles" class="hoverable">
                        <app-aggregate icon="directions_car" title="VEHICLES" [value]="dashboardService.vehiclesCount | async"
                            [valueColor]="'blue'">
                        </app-aggregate>
                    </mat-card>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                    <mat-card routerLink="/queries" class="hoverable">
                        <app-aggregate icon="find_in_page" title="QUERIES" [value]="dashboardService.queriesCount | async"
                            [valueColor]="'blue'">
                        </app-aggregate>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>