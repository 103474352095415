import { Component, Input, OnInit } from '@angular/core';
import { IQuery } from 'src/app/core/entities/query';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { QEventsService, QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { environment } from 'src/environments/environment';
import { RatesService } from 'src/app/services/CRUD/recogeme/rates.service';
import { IRate } from 'src/app/core/entities/rate';
import { RecogemeTablePageBase } from 'src/app/core/base/recogeme.table.base';
import { VehicleTypes } from 'src/app/core/entities/vehicle';

@Component({
  selector: 'app-rates-list',
  templateUrl: './rates-list.component.html',
})
export class RatesListComponent
  extends RecogemeTablePageBase<IQuery>
  implements OnInit
{
  displayedColumns: string[] = ['name', 'type', 'created_at', 'options'];
  query: string;
  isLoading = false;
  filterByOwner = true;
  env = environment;

  @Input() showBreadcrumbs = true;
  public autocompleteData = {
    vehicle_type: VehicleTypes.map(
      RecogemeTablePageBase.createAutocompleteItem
    ),
  };

  constructor(
    public rates$: RatesService,
    public snackbar: QSnackBar,
    public dialogs: DialogsService,
    public events: QEventsService,
    public app: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(snackbar, events, router, activatedRoute);
    this.initialSearch = true;
    this.autoRefresh = true;
  }

  public getSearchObservable(queryParams: {
    [key: string]: string;
  }): Observable<any> {
    return this.rates$.listAll(queryParams);
  }

  public getRemoveItemObservable(id: string): Observable<any> {
    return this.rates$.remove(id);
  }

  public getRemoveItemDialog(id: string) {
    return this.dialogs.openConfirmDelete();
  }

  public addNewRate() {
    this.dialogs
      .openCreateRate()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.searchParams);
      });
  }

  public editRate(rate: IRate) {
    this.dialogs
      .openEditRate(rate)
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.searchParams);
      });
  }

  public getOwner(): string {
    return this.app.getOwner();
  }
}
