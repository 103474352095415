import { Record } from 'src/app/core/entities/record';

export interface IVehicle {
  id: string;
  records: Record[];
  brand?: string;
  make?: string;
  color?: string;
  chapa: string;
  created_at: string;
  doors: string;
  general: string;
  indicators: string;
  license_plate: string;
  lights: string;
  rear_mirrors: string;
  tunning: boolean;
  type: VehicleType;
  updated_at: string;
  windows: string;
  rims: string;
  tires: string;
}

export const VehicleTypes = <const>[
  'car',
  'bicicle',
  'motorbike',
  'ciclomotor',
];
export type VehicleType = typeof VehicleTypes[number];

export class Vehicle implements IVehicle {
  static VehicleTypes: VehicleType[] = [
    'car',
    'bicicle',
    'motorbike',
    'ciclomotor',
  ];
  id: string;
  brand?: string;
  make?: string;
  color?: string;
  created_at: string;
  type: VehicleType;
  updated_at: string;
  license_plate: string;
  records: Record[];

  chapa: string;
  doors: string;
  general: string;
  indicators: string;
  lights: string;
  rear_mirrors: string;
  tunning: boolean;
  windows: string;
  tires: string;
  rims: string;

  fromJson(obj: IVehicle): Vehicle {
    const record = new Vehicle();
    // tslint:disable-next-line: forin
    for (const key in obj) {
      if (key === 'records') {
        record[key] = obj[key].map((el) => new Record().fromJson(el));
      } else {
        record[key] = obj[key];
      }
    }
    return record;
  }
}
