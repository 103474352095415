import { User } from '@qbitartifacts/caste-client-ng';
import { SerializableEntity } from '../base/serializable-entity.base';
import { IBill } from './bill';
import { Document, IDocument } from './document';
import { IRecogemeLocation } from './location';
import { IMedia, Media } from './media';
import { IQuery } from './query';
import { IVehicle, Vehicle } from './vehicle';

export interface IRecord {
  internal_id: string;
  id: string;
  created_at: string;
  updated_at: string;
  started_at: string;
  finished_at: string;
  destiny_location: IRecogemeLocation;
  origin_location: IRecogemeLocation;
  displacement_type: DisplacementType;
  driver: any;
  bill: IBill;
  user_id: string;
  hook_type: HookType;
  images: IMedia[];
  documents: IDocument[];
  queries: IQuery[];
  observations: string;
  signature: IMedia;
  status: RecordStatus;
  vehicle: IVehicle;
  inmobilized_documentation_presented: boolean;
  in_scrapping: boolean;
  inmobilized: boolean;
  charged_on_route: boolean;
  user?: { username: string; data: any };
}

export type HookType = 'Trasero' | 'Delantero';

export const DisplacementTypes = <const>['EN', 'AP'];
export type DisplacementType = typeof DisplacementTypes[number];

export const RecordStatuses = <const>[
  'finalized',
  'in-progress',
  'queried',
  'created',
  'delivered',
];
export type RecordStatus = typeof RecordStatuses[number];

export class Record implements SerializableEntity, IRecord {
  static displacementTypes: DisplacementType[] = ['EN', 'AP'];

  destiny_location: IRecogemeLocation;
  origin_location: IRecogemeLocation;
  displacement_type: DisplacementType;
  driver: User;
  hook_type: HookType;
  images: IMedia[];
  queries: IQuery[];
  observations: string;
  signature: IMedia;
  documents: IDocument[];
  status: RecordStatus;
  vehicle: IVehicle;
  id: string;
  user_id: string;
  user?: { username: string; data: any };
  bill: IBill;
  internal_id: string;
  created_at: string;
  updated_at: string;

  started_at: string;
  finished_at: string;

  inmobilized_documentation_presented: boolean;
  inmobilized: boolean;
  in_scrapping: boolean;
  charged_on_route: boolean;

  // Used in selectors
  get name() {
    return `${this.vehicle.license_plate} (${this.internal_id})`;
  }

  fromJson(obj: IRecord): Record {
    const record = new Record();
    // tslint:disable-next-line: forin
    for (const key in obj) {
      if (key === 'vehicle') {
        record[key] = new Vehicle().fromJson(obj[key]);
        continue;
      }

      if (key === 'images') {
        record[key] = obj[key].map((el) => new Media().fromJson(el));
        continue;
      }

      if (key === 'signature') {
        record[key] = new Media().fromJson(obj[key]);
        continue;
      }

      if (key === 'documents') {
        record[key] = obj[key].map((el) => new Document().fromJson(el));
        continue;
      }

      record[key] = obj[key];
    }
    return record;
  }

  toJson?(obj: {}): void {}
}
