import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-custom-slider',
  templateUrl: './custom-slider.component.html',
  styleUrls: ['./custom-slider.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CustomSliderComponent }],
})
export class CustomSliderComponent extends CdkStepper {
  @Input() showAddButton = false;
  @Output() onAddDocument: EventEmitter<any> = new EventEmitter();

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
