import { BillStatus, IBill, PaymentType } from 'src/app/core/entities/bill';
import { IRate } from 'src/app/core/entities/rate';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { IRecord } from 'src/app/core/entities/record';
import { BillsService } from 'src/app/services/CRUD/recogeme/bills.service';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { isDniValidator } from 'src/app/core/utilities/validators/dni-validator';
import * as moment from 'moment';

export interface EditBillData {
  bill: IBill;
  record: IRecord;
}

@Component({
  selector: 'app-edit-bill',
  templateUrl: './edit-bill.component.html',
})
export class EditBillComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public record: IRecord;
  public rate: IRate;
  public bill: IBill;
  public paymentTypes: PaymentType[] = ['card', 'cash', 'mixed'];
  public status: BillStatus;
  public recordSelectorFilters = {};
  public rateSelectorFilters = {};

  constructor(
    public dialogRef: MatDialogRef<EditBillComponent>,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public billService: BillsService,
    public app: AppService,
    public dialogs: DialogsService,
    @Inject(MAT_DIALOG_DATA) public data: EditBillData
  ) {
    this.record = data.record;
    this.bill = data.bill;
    this.rate = data.bill.rate;
    this.status = data.bill.status;
  }

  ngOnInit() {
    this.recordSelectorFilters = {
      account_id: this.app.getOwner(),
      // Checks that records have no bills
      'exists[bill]': false,
      displacement_type: 'EN',
    };
    this.rateSelectorFilters = { account_id: this.app.getOwner() };

    this.form = this.formBuilder.group({
      name: [this.bill.name],
      last_name: [this.bill.last_name],
      payment_type: [this.bill.payment_type],
      city: [this.bill.city],
      address: [this.bill.address],
      email: [this.bill.email, Validators.email],
      dni: [this.bill.dni, isDniValidator],
      total_cash: [this.bill.total_cash, Validators.required],
      total_card: [this.bill.total_card, Validators.required],
      dated_at: [
        moment(this.bill.dated_at).format('yyyy-MM-DDTHH:mm'),
        Validators.required,
      ],
    });

    this.paymentTypeTotalsVisibility();
  }
  
  get hasChanged(): boolean {
    const formChanged = this.form.dirty;
    const statusChanged = this.status !== this.bill.status;

    return formChanged || statusChanged;
  }

  /* istanbul ignore next */
  edit() {
    if (!this.hasChanged || this.isLoading) {
      return;
    }

    if(!this.rate) {
      this.snackbar.open('PLEASE_SELECT_RATE');
      return;
    }

    this.setIsLoading(true);

    const data = {
      ...this.form.value,
      rate: this.rate['@id'],
      record: this.record['@id'],
    };

    if (this.status != this.bill.status) {
      data.status = this.status;
    }

    this.billService.update(this.bill.id, data).subscribe(
      (resp) => {
        this.snackbar.open('Factura creada');
        this.close(true);
        this.setIsLoading(false);
      },
      (err) => {
        this.setIsLoading(false);
        this.snackbar.open(err.message);
      }
    );
  }

  get email() {
    return this.form.get('email');
  }

  get dni() {
    return this.form.get('dni');
  }

  get payment_type() {
    return this.form.get('payment_type');
  }

  get total_cash() {
    return this.form.get('total_cash');
  }

  get total_card() {
    return this.form.get('total_card');
  }

  get amount() {
    return this.form.get('amount');
  }

  get dated_at() {
    return this.form.get('dated_at');
  }

  addDocument() {
    this.dialogs
      .openAddDocument(this.record)
      .afterClosed()
      .subscribe((e) => {
        if (e) {
          // this.loadItem();
          this.snackbar.open('DOCUMENT_ADDED_CORRECTLY');
        }
      });
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }

  paymentTypeTotals() {

    const divPaymentTotals = document.getElementById('divPaymentTotals');

    switch (this.payment_type.value)
    {
        case null:
            this.total_cash.setValue(0);
            this.total_card.setValue(0);
            divPaymentTotals.style.display = 'none';

            break;

        case 'cash':
            this.total_cash.setValue(0);
            this.total_card.setValue(0);
            divPaymentTotals.style.display = 'none';

            break;

        case 'card':
            this.total_cash.setValue(0);
            this.total_card.setValue(0);
            divPaymentTotals.style.display = 'none';

            break;

        case 'mixed':
            this.total_cash.setValue(0);
            this.total_card.setValue(0);
            divPaymentTotals.style.display = 'flex';

            break;
    }
  }

  paymentTypeTotalsVisibility() {

    const divPaymentTotals = document.getElementById('divPaymentTotals');

    switch (this.payment_type.value)
    {
        case null:
            divPaymentTotals.style.display = 'none';

            break;

        case 'cash':
            divPaymentTotals.style.display = 'none';

            break;

        case 'card':
            divPaymentTotals.style.display = 'none';

            break;

        case 'mixed':
            divPaymentTotals.style.display = 'flex';

            break;
    }
  }
}
