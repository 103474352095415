import { PageBaseComponent } from 'src/app/core/base/page.base';
import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent extends PageBaseComponent
  implements AfterContentInit {
  static guards: any[] = [AuthedGuard];
  public title = 'VEHICLES';

  constructor(
    title: Title,
    translate$: TranslateService,
    public route: ActivatedRoute,
  ) {
    super(title, translate$);
  }
}
