<qbit-dialog-header title="{{'CREATE_CONFIG' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="add()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-mutted marg-bottom-15" style="margin-top:0!important">
                    {{'CREATE_CONFIG_DESC' | translate}}
                </p>

                <div class="row marg-bottom-20">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'COMPANY_NAME'|translate}}</mat-label>
                            <input matInput type="text" formControlName="company_name">
                        </mat-form-field>
                    </div>
                    
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'BILL_HEADER'|translate}}</mat-label>
                            <input matInput type="text" formControlName="bill_header">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'BILL_FOOTER'|translate}}</mat-label>
                            <input matInput type="text" formControlName="bill_footer">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <app-logo-picker [logo]="logo" (onLogoPicked)="logoPicked($event)"></app-logo-picker>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div [@.disabled]="disableAnimation">
                            <mat-accordion>
                                <mat-expansion-panel [expanded]="false" class="mat-elevation-z1">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{'LOCATIONS' | translate}}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{'LOCATIONS_DESC' | translate}}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-2"></div>
                                        <div class="col-8"></div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [disabled]="isLoading || form.invalid" [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>