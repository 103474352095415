import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CasteAuthConfig,
  CasteUsersService,
  CASTE_AUTH_CONFIG,
  DEFAULT_CONFIG,
} from '@qbitartifacts/caste-client-ng';
import { UserType } from '@qbitartifacts/caste-client-ng/lib/types';
import { RequestCacheService } from '@qbitartifacts/qbit-kit-ng';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root',
})
export class CasteUsersServiceOverride extends CasteUsersService {
  constructor(
    @Inject(CASTE_AUTH_CONFIG) config: CasteAuthConfig,
    http: HttpClient,
    private cache: RequestCacheService
  ) {
    super(
      {
        ...DEFAULT_CONFIG,
        baseHeaders: {
          accept: 'application/ld+json',
        },
        ...config,
      },
      http
    );
  }

  getOne(id: string, userType: UserType = 'user') {
    const url = `/${userType}/${this.endpoint}/${id}`;
    const cached = this.cache.get(url);

    if (cached) return of(cached);

    return super.getOne(id, userType).pipe(
      tap((event) => {
        if (event instanceof HttpResponse || event['@id']) {
          this.cache.set(url, event);
        }
      })
    );
  }

  static getProvider() {
    return {
      provide: CasteUsersService,
      useClass: CasteUsersServiceOverride,
    };
  }
}
