import { Component, Input } from '@angular/core';
import { IRate } from 'src/app/core/entities/rate';

@Component({
  selector: 'app-rate-chip',
  templateUrl: './rate-chip.component.html',
})
export class RateChipComponent {
  @Input() rate: IRate;
  @Input() tooltip = 'RATE';
}
