import { environment } from 'src/environments/environment';
import { IMedia, Media } from './media';

export interface IDocument extends IMedia {}

export class Document extends Media {
  fromJson(obj: IDocument): Document {
    const record = new Document();
    // tslint:disable-next-line: forin
    for (const key in obj) {
      record[key] = obj[key];
    }
    return record;
  }

  get name() {
    const parts = this.file_path.split('.');
    parts.pop(); // remove extension
    return parts.pop();
  }

  get extension() {
    return this.file_path.split('.').pop();
  }

  get isImage(): boolean {
    return /.(jpg|jpeg|png|svg)/.test(this.file_path);
  }

  get src() {
    return `${environment.url}${this.content_url}`;
  }
}
