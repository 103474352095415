import { NgModule } from '@angular/core';
import {
  QBreadcrumbsModule,
  QTableHeaderModule,
  QTableFiltersModule,
  QbitKitBaseModule,
  QLangSelectorModule,
  QLoadableButtonModule,
  QListLoadingModule,
  QDialogHeaderModule,
  QChipsModule,
  SaComponentsModule,
  RequestCacheModule,
  QSnackBarModule,
  QTableFiltersAddableModule,
} from '@qbitartifacts/qbit-kit-ng';

const modules = [
  QBreadcrumbsModule,
  QTableHeaderModule,
  QTableFiltersModule,
  QTableFiltersAddableModule,
  QbitKitBaseModule,
  QLangSelectorModule,
  QLoadableButtonModule,
  QListLoadingModule,
  QDialogHeaderModule,
  QChipsModule,
  QSnackBarModule,
  SaComponentsModule,
  RequestCacheModule,
];

@NgModule({
  imports: modules,
  exports: modules,
})
export class QbitKitModule {}
