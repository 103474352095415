import { Component, OnInit, AfterContentInit } from '@angular/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ItemPageBaseComponent } from 'src/app/core/base/item.page.base';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
})
export class UserComponent extends ItemPageBaseComponent
  implements AfterContentInit, OnInit {
  static guards: any[] = [AuthedGuard, AdminGuard];
  public title = 'USER';

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public route: ActivatedRoute
  ) {
    super(title$, translate$, route);
  }

  getItemObservable(id: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
  }
}
