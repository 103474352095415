import { Component, OnInit } from '@angular/core';
import { IMedia } from 'src/app/core/entities/media';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaService } from 'src/app/services/CRUD/recogeme/media.service';
import {
  QEventsService,
  QSnackBar,
  QTableBase,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
})
export class MediaListComponent extends QTableBase<IMedia> implements OnInit {
  displayedColumns: string[] = ['preview', 'record', 'vehicle', 'created_at'];
  query: string;
  isLoading = false;
  filterByOwner = true;
  env = environment;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: true,
  };

  constructor(
    public snackbar: QSnackBar,
    public dialogs: DialogsService,
    public events: QEventsService,
    public app: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public media$: MediaService
  ) {
    super(snackbar, events, router, activatedRoute);
    this.initialSearch = true;
    this.autoRefresh = true;
  }

  public getSearchObservable(queryParams: {
    [key: string]: string;
  }): Observable<any> {
    if (queryParams.account_id) {
      queryParams['record.account_id'] = queryParams.account_id;
    }

    return this.media$.listAll(queryParams);
  }

  public getRemoveItemObservable(id: string): Observable<any> {
    return this.media$.remove(id);
  }

  public getRemoveItemDialog(id: string) {
    return this.dialogs.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app.getOwner();
  }
}
