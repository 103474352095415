<section class="example-container">
    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>

    <footer class="example-step-navigation-bar">
        <div class="row">
            <div class="col-lg-2">
                <div class="flex-row centered">
                    <button mat-button cdkStepperPrevious>&larr;</button>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="flex-row centered space-between">
                    <ng-container *ngFor="let step of steps; let i = index">
                        <button mat-button class="example-step" [class.example-active]="selectedIndex === i"
                            (click)="selectStepByIndex(i)">
                            {{ i + 1 }}
                        </button>
                    </ng-container>
                    <button *ngIf="showAddButton" mat-button class="example-step" (click)="onAddDocument.emit()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="flex-row centered">
                    <button mat-button cdkStepperNext>&rarr;</button>
                </div>
            </div>
        </div>
    </footer>
</section>