import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '../../services/auth.service';
import { BaseService, DEFAULT_CONFIG } from '@qbitartifacts/caste-client-ng';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { countItems } from '../utilities/pipes/count-items';

export interface CrudServiceOptions {
  endpoint: string;
}

export const CRUD_ROLES = {
  public: 'public',
  admin: 'admin',
  none: '',
};

export abstract class CrudBaseService<
  T = any
> extends BaseService<CrudServiceOptions> {
  constructor(config: any, http: HttpClient, private auth: AuthService) {
    super(http, {
      ...DEFAULT_CONFIG,
      ...config,
      baseHeaders: {
        accept: 'application/ld+json',
      },
      url: environment.url,
    });
  }

  protected getToken() {
    return this.auth.session && this.auth.session.token;
  }

  protected createPathFromParts(userType: string, path: string) {
    return userType ? `/${userType}${path}` : path;
  }

  public create(data: Partial<T>, userType?: string): Observable<T> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );
    return this.post<T>(path, data);
  }

  public listAll(
    params?: { [key: string]: string },
    userType?: string
  ): Observable<T[]> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );
    return this.get<T[]>(path, params);
  }

  public getOne(id: string, userType?: string): Observable<T> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}`
    );
    return this.get<T>(path);
  }

  public update(
    id: string,
    data: Partial<T>,
    userType?: string
  ): Observable<T> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}`
    );
    return this.put<T>(path, data);
  }

  public remove(id: string, userType?: string): Observable<any> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}`
    );
    return this.delete(path);
  }

  public getTotalItems(
    params: any = {},
    userType: string = ''
  ): Observable<number> {
    return CrudBaseService.getTotalFromRequest(this.listAll(params, userType));
  }

  /* istanbul ignore next */
  public extractData(res: any) {
    if (res && res['hydra:member']) {
      const data = res['hydra:member'];

      const mapping = res['hydra:search']
        ? res['hydra:search']['hydra:mapping'].map((el) => {
            if (el.variable.includes('order')) {
              el.type = 'order';
            } else if (!el.variable.includes('[]')) {
              el.type = 'search';
            }
            return el;
          })
        : [];

      return {
        data,
        search: mapping.filter((el) => el.type === 'search'),
        order: mapping.filter((el) => el.type === 'order'),
        total: res['hydra:totalItems'],
      };
    }

    return res;
  }

  /* istanbul ignore next */
  public handleError(err) {
    const error = err.originalError || err.error || err;

    if (error['hydra:description']) {
      error.message = error['hydra:description'];
    }

    return throwError(error);
  }

  static getTotalFromRequest(request: Observable<any>) {
    return request.pipe(countItems);
  }
}
