import { Component, Input } from '@angular/core';
import { QApiSelectorComponent } from '@qbitartifacts/qbit-kit-ng';
import { Observable } from 'rxjs/internal/Observable';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';

@Component({
  selector: 'app-record-selector',
  templateUrl: './record-selector.component.html',
})
export class RecordSelectorComponent extends QApiSelectorComponent {
  label: string = 'SELECT_RECORD';
  @Input() public userType: any = '';

  constructor(public records$: RecordsService) {
    super();
  }

  getSearchObservable(query: string): Observable<any> {
    return this.records$.listAll(
      { ...this.filters, 'vehicle.license_plate': query },
      this.userType
    );
  }
}
