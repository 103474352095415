import { Component, Input } from '@angular/core';
import { Vehicle } from 'src/app/core/entities/vehicle';
import { VehicleTypeIconComponent } from '../vehicle-type-icon/vehicle-type-icon.component';

@Component({
  selector: 'app-vehicle-chip',
  templateUrl: './vehicle-chip.component.html',
})
export class VehicleChipComponent {
  @Input() vehicle: Vehicle;
  @Input() tooltip = 'VEHICLE';

  icon: string;

  ngOnInit() {
    this.icon = VehicleTypeIconComponent.getVehicleIcon(this.vehicle.type);
  }
}
