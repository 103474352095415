import { Routes } from '@angular/router';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { ProfileComponent } from '../pages/items/profile/profile.component';
import { RecordComponent } from '../pages/items/record/record.component';
import { UserComponent } from '../pages/items/user/user.component';
import { VehicleComponent } from '../pages/items/vehicle/vehicle.component';
import { BillsComponent } from '../pages/lists/bills/bills.component';
import { MediaComponent } from '../pages/lists/media/media.component';
import { QueriesComponent } from '../pages/lists/queries/queries.component';
import { RatesComponent } from '../pages/lists/rates/rates.component';
import { RecordsComponent } from '../pages/lists/records/records.component';
import { VehiclesComponent } from '../pages/lists/vehicles/vehicles.component';
import { ForgotPassComponent } from '../pages/public/forgot-pass/forgot-pass.component';
import { LoginComponent } from '../pages/public/login/login.component';
import { RecoverComponent } from '../pages/public/recover/recover.component';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: LoginComponent.guards,
  },
  {
    path: 'forgot-password',
    component: ForgotPassComponent,
    canActivate: ForgotPassComponent.guards,
  },
  {
    path: 'recover/:user_id',
    component: RecoverComponent,
    canActivate: RecoverComponent.guards,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: DashboardComponent.guards,
    data: {
      breadcrumb: 'DASHBOARD',
    },
  },
  {
    path: 'records',
    component: RecordsComponent,
    canActivate: RecordsComponent.guards,
    children: [
      {
        path: ':id',
        component: RecordComponent,
        canActivate: RecordComponent.guards,
        data: {
          breadcrumb: 'RECORD',
        },
      },
    ],
    data: {
      breadcrumb: 'RECORDS',
    },
  },
  {
    path: 'vehicles',
    component: VehiclesComponent,
    canActivate: VehiclesComponent.guards,
    children: [
      {
        path: ':id',
        component: VehicleComponent,
        canActivate: VehicleComponent.guards,
        data: {
          breadcrumb: 'VEHICLE',
        },
      },
    ],
    data: {
      breadcrumb: 'VEHICLES',
    },
  },
  {
    path: 'images',
    component: MediaComponent,
    canActivate: MediaComponent.guards,
    data: {
      breadcrumb: 'IMAGES',
    },
  },

  {
    path: 'queries',
    component: QueriesComponent,
    canActivate: QueriesComponent.guards,
    data: {
      breadcrumb: 'QUERIES',
    },
  },
  {
    path: 'rates',
    component: RatesComponent,
    canActivate: RatesComponent.guards,
    data: {
      breadcrumb: 'RATES',
    },
  },
  {
    path: 'bills',
    component: BillsComponent,
    canActivate: BillsComponent.guards,
    data: {
      breadcrumb: 'BILLS',
    },
  },

  {
    path: 'users/:id',
    component: UserComponent,
    canActivate: UserComponent.guards,
    data: {
      breadcrumb: 'User',
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: ProfileComponent.guards,
    data: {
      breadcrumb: 'Profile',
    },
  },
];
