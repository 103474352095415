import { IVehicle, Vehicle } from 'src/app/core/entities/vehicle';
import { Component, OnInit, AfterContentInit } from '@angular/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ItemPageBaseComponent } from 'src/app/core/base/item.page.base';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { VehiclesService } from 'src/app/services/CRUD/recogeme/vehicles.service';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';
import { EditVehicleComponent } from 'src/app/dialogs/edit-vehicle/edit-vehicle.component';
import { DialogsService } from 'src/app/services/dialogs.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent
  extends ItemPageBaseComponent<IVehicle>
  implements AfterContentInit {
  static guards: any[] = [AuthedGuard];
  public title = 'VEHICLE';

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public route: ActivatedRoute,
    public vehicles$: VehiclesService,
    public breadcrumb$: QBreadcrumbsService,
    public user$: UserService,
    public dialogs: DialogsService
  ) {
    super(title$, translate$, route);
  }

  mapItem(item) {
    const vehicle = new Vehicle().fromJson(item);
    return vehicle;
  }

  getItemObservable(id: string): Observable<any> {
    return this.vehicles$.getOne(id);
  }

  openEdit(item: any) {
    this.dialogs
      .open(EditVehicleComponent, item)
      .afterClosed()
      .subscribe((e) => {
        if (e) this.loadItem();
      });
  }

  onGotItem(item: Vehicle) {
    this.breadcrumb$.editCurrent(item.license_plate || this.title);
  }
}
