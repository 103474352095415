import { Component, AfterContentInit } from '@angular/core';
import { AuthedGuard } from 'src/app/core/guards/authed.guard';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ItemPageBaseComponent } from 'src/app/core/base/item.page.base';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';
import { IRecord, Record } from 'src/app/core/entities/record';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';
import { DialogsService } from 'src/app/services/dialogs.service';
import { EditRecordComponent } from 'src/app/dialogs/edit-record/edit-record.component';
import { EditVehicleComponent } from 'src/app/dialogs/edit-vehicle/edit-vehicle.component';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { RecogemeGrants } from 'src/app/config/grants';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
})
export class RecordComponent
  extends ItemPageBaseComponent<IRecord>
  implements AfterContentInit
{
  static guards: any[] = [AuthedGuard];
  public title = 'RECORD';
  environment = environment;
  public isBillingOfficer = false;

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public route: ActivatedRoute,
    public records$: RecordsService,
    public breadcrumb$: QBreadcrumbsService,
    public dialogs: DialogsService,
    public user$: UserService
  ) {
    super(title$, translate$, route);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isBillingOfficer = this.user$.user.hasPermissionForAccount(
      localStorage.getItem('selected_account'),
      RecogemeGrants.BILLING_OFFICER
    );
  }

  mapItem(item) {
    return new Record().fromJson(item);
  }

  getItemObservable(id: string): Observable<IRecord> {
    return this.records$.getOne(id);
  }

  openEdit(item: any) {
    this.dialogs
      .open(EditRecordComponent, item)
      .afterClosed()
      .subscribe((e) => {
        if (e) this.loadItem();
      });
  }

  openEditVehicle(vehicle) {
    this.dialogs
      .open(EditVehicleComponent, vehicle)
      .afterClosed()
      .subscribe((e) => {
        if (e) this.loadItem();
      });
  }

  addDocument() {
    this.dialogs
      .openAddDocument(this.itemData)
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.loadItem();
        }
      });
  }

  seeSignature(item: any) {
    this.dialogs.openImagePreview({
      imageUrl: item.signature.src,
      title: 'VIEW_SIGNATURE',
      subtitle: 'SIGNATURE_DESC',
    });
  }

  addBill() {
    this.dialogs
      .openCreateBill({
        record: this.itemData,
        disableRecordSelector: true,
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.loadItem();
        }
      });
  }

  editBill() {
    this.dialogs
      .openEditBill({ record: this.itemData, bill: this.itemData.bill })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.loadItem();
        }
      });
  }

  onGotItem(item: Record) {
    this.breadcrumb$.editCurrent(
      item.internal_id || item.vehicle.license_plate || this.title
    );
  }
}
