import { Component, Input, OnInit, Query } from '@angular/core';
import { IQuery } from 'src/app/core/entities/query';
import { Observable, of } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { QueriesService } from 'src/app/services/CRUD/recogeme/queries.service';
import { Router, ActivatedRoute } from '@angular/router';
import { QEventsService, QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { environment } from 'src/environments/environment';
import { RecogemeTablePageBase } from 'src/app/core/base/recogeme.table.base';

@Component({
  selector: 'app-queries-list',
  templateUrl: './queries-list.component.html',
})
export class QueriesListComponent
  extends RecogemeTablePageBase<IQuery>
  implements OnInit
{
  displayedColumns: string[] = ['license_plate', 'ip', 'record', 'created_at'];
  query: string;
  isLoading = false;
  filterByOwner = true;
  env = environment;

  @Input() itemQueries: Query[];
  @Input() showBreadcrumbs = true;

  constructor(
    public snackbar: QSnackBar,
    public dialogs: DialogsService,
    public events: QEventsService,
    public app: AppService,
    public queries$: QueriesService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(snackbar, events, router, activatedRoute);
    this.initialSearch = true;
    this.autoRefresh = true;
  }

  public getSearchObservable(
    queryParams: {
      [key: string]: string;
    } = {}
  ): Observable<any> {
    if (queryParams.account_id) {
      queryParams['record.account_id'] = queryParams.account_id;
    }

    if (this.itemQueries) {
      return of({
        data: this.itemQueries,
        total: this.itemQueries.length,
        search: [],
      });
    } else {
      return this.queries$.listAll(queryParams);
    }
  }

  public getRemoveItemObservable(id: string): Observable<any> {
    return this.queries$.remove(id);
  }

  public getRemoveItemDialog(id: string) {
    return this.dialogs.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app.getOwner();
  }
}
