<mat-toolbar class="toolbar toolbar-white mat-elevation-z1">
    <mat-toolbar-row>
        <button id="sidemenu-toggle" (click)="toggleSidemenu()" mat-icon-button aria-label="Sidemenu toggle">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="marg-left-10">{{environment.brand.title}} · <code>{{environment.version}}</code></span>
        <span class="spacer"></span>
        <qbit-lang-selector></qbit-lang-selector>
        <app-user-avatar-menu></app-user-avatar-menu>
    </mat-toolbar-row>
</mat-toolbar>