import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComponentsModule } from '../core/components/components.module';
import { ProfileComponent } from './items/profile/profile.component';
import { UserComponent } from './items/user/user.component';
import { ClientsComponent } from './lists/clients/clients.component';
import { MediaComponent } from './lists/media/media.component';
import { QueriesComponent } from './lists/queries/queries.component';
import { RecordsComponent } from './lists/records/records.component';
import { VehiclesComponent } from './lists/vehicles/vehicles.component';
import { ForgotPassComponent } from './public/forgot-pass/forgot-pass.component';
import { LoginComponent } from './public/login/login.component';
import { RecoverComponent } from './public/recover/recover.component';
import { RecordComponent } from './items/record/record.component';
import { VehicleComponent } from './items/vehicle/vehicle.component';
import { BillsComponent } from './lists/bills/bills.component';
import { RatesComponent } from './lists/rates/rates.component';

const pages = [
  DashboardComponent,
  ForgotPassComponent,
  LoginComponent,
  ProfileComponent,
  RecoverComponent,

  // List pages
  RecordsComponent,
  VehiclesComponent,
  QueriesComponent,
  MediaComponent,
  ClientsComponent,
  BillsComponent,
  RatesComponent,

  // Item Components
  RecordComponent,
  VehicleComponent,
  UserComponent,
];

@NgModule({
  declarations: pages,
  exports: pages,
  imports: [SharedModule, ComponentsModule],
})
export class PagesModule {}
