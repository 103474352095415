<qbit-dialog-header title="{{'EDIT_RATE' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="edit()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-mutted marg-bottom-15" style="margin-top:0!important">{{'EDIT_RATE_DESC' | translate}}
                </p>

                <div class="row  marg-bottom-20">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <mat-form-field>
                            <mat-label>{{'NAME'|translate}}</mat-label>
                            <input matInput type="text" formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <app-vehicle-type-selector [(item)]="rateData.vehicle_type"> </app-vehicle-type-selector>
                    </div>
                </div>

                <div [@.disabled]="disableAnimation">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="false" class="mat-elevation-z1">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>attach_money</mat-icon>
                                    {{'PRICES' | translate}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{'PRICES_DESCRIPTION' | translate}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="row  marg-bottom-20">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'DAY_PRICE'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="day_price">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'HOUR_PRICE'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="hour_price">
                                        <mat-hint>{{'HOUR_PRICE_DESC'|translate}}</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row  marg-bottom-20">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'BASE_PRICE_BEFORE_UNHOOKING'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="base_price_before_unhooking">
                                        <mat-hint>{{'BASE_PRICE_BEFORE_UNHOOKING_DESC'|translate}}</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'BASE_PRICE_AFTER_UNHOOKING'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="base_price_after_unhooking">
                                        <mat-hint>{{'BASE_PRICE_AFTER_UNHOOKING_DESC'|translate}}</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="false" class="mat-elevation-z1">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>date_range</mat-icon>
                                    {{'TIMES' | translate}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{'TIMES_DESCRIPTION' | translate}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="row  marg-bottom-20">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'MIN_STRIP_MINUTES'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="min_strip_minutes">
                                        <mat-hint>{{'MIN_STRIP_MINUTES_DESC'|translate}}</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'MAX_DAYS'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="max_days">
                                        <mat-hint>{{'MAX_DAYS_DESC'|translate}}</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row  marg-bottom-20">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'MAX_HOURS_PER_DAY'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="max_hours_per_day">
                                        <mat-hint>{{'MAX_HOURS_PER_DAY_DESC'|translate}}</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <mat-form-field>
                                        <mat-label>{{'DAYS_HOURLY_BILLING_ALLOWED'|translate}}</mat-label>
                                        <input matInput type="number" formControlName="days_hourly_billing_allowed">
                                        <mat-hint>{{'DAYS_HOURLY_BILLING_ALLOWED_DESC'|translate}}</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>


                <div class="flex-row end">
                    <qbit-loadable-button [disabled]="form.invalid || isLoading" [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>